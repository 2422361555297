import { useQuery } from '@tanstack/react-query';
import { supabase } from '../integrations/supabase/supabase';

export const useCabalsList = () => {
  return useQuery({
    queryKey: ['public-cabals'],
    queryFn: async () => {
      const { data: teams, error } = await supabase
        .from('teams')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;

      if (!teams || teams.length === 0) {
        return [];
      }

      return teams.map(team => ({
        id: team.id,
        cabal_name: team.cabal_name || 'Unnamed Cabal',
        cabal_description: team.cabal_description || 'No description available',
        cabal_image: team.cabal_image || '/placeholder.svg',
        memberCount: team.team_members ? 
          (Array.isArray(team.team_members) ? team.team_members.length : 
           Object.keys(team.team_members).length) : 0,
        status: 'active'
      }));
    },
    refetchInterval: 30000
  });
};