import React, { useState } from 'react';
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { X } from "lucide-react";
import { toast } from "@/components/ui/use-toast";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "../integrations/supabase/supabase";

const TeamMemberChipInput = ({ members, onMembersChange }) => {
  const [currentInput, setCurrentInput] = useState('');

  // Query to check if address is already registered
  const { refetch: checkAddress } = useQuery({
    queryKey: ['checkTeamMemberAddress', currentInput],
    queryFn: async () => {
      if (!currentInput) return null;
      
      const { data, error } = await supabase
        .from('trader_profiles')
        .select('address, cabal_name')
        .eq('address', currentInput)
        .single();
        
      if (error && error.code !== 'PGRST116') {
        throw error;
      }
      return data;
    },
    enabled: false
  });

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault();
      addMember();
    }
  };

  const addMember = async () => {
    const trimmedInput = currentInput.trim();
    if (!trimmedInput) return;

    if (members.length >= 100) {
      toast({
        title: "Maximum limit reached",
        description: "You can't add more than 100 team members",
        variant: "destructive",
      });
      return;
    }

    if (members.includes(trimmedInput)) {
      toast({
        title: "Duplicate address",
        description: "This address has already been added to your team",
        variant: "destructive",
      });
      return;
    }

    // Check if address is already registered with another cabal
    const { data } = await checkAddress();
    if (data) {
      toast({
        title: "Address already registered",
        description: `This address is already registered with cabal: ${data.cabal_name}`,
        variant: "destructive",
      });
      return;
    }

    onMembersChange([...members, trimmedInput]);
    setCurrentInput('');
  };

  const removeMember = (indexToRemove) => {
    onMembersChange(members.filter((_, index) => index !== indexToRemove));
  };

  return (
    <div className="space-y-2">
      <div className="flex gap-2">
        <Input
          value={currentInput}
          onChange={(e) => setCurrentInput(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Enter address and press Enter"
          className="flex-grow bg-[#1A1A1A] border border-[#00A0B8] p-1 focus:outline-none focus:ring-1 focus:ring-[#00A0B8] text-[#CBE9ED]"
        />
        <Button 
          onClick={addMember}
          className="bg-[#1A1A1A] border border-[#00A0B8] text-[#00A0B8] hover:bg-[#002227]"
        >
          Add
        </Button>
      </div>
      <div className="flex flex-wrap gap-2 mt-2">
        {members.map((member, index) => (
          <div
            key={index}
            className="flex items-center gap-1 bg-[#1A1A1A] border border-[#00A0B8] px-2 py-1 rounded-full text-[#CBE9ED]"
          >
            <span className="text-xs">{member}</span>
            <button
              onClick={() => removeMember(index)}
              className="text-[#F02D3B] hover:text-[#FF4D4D] focus:outline-none"
            >
              <X size={14} />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamMemberChipInput;