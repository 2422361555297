import React from 'react';
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";

const CabalInfoForm = ({ formData, setFormData }) => {
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        toast({
          title: "File too large",
          description: "Please select an image under 5MB",
          variant: "destructive",
        });
        return;
      }
      setFormData({ ...formData, cabalLogo: file });
    }
  };

  return (
    <>
      <div className="mb-4">
        <Label htmlFor="cabalName" className="block mb-1 relative pl-4">
          <span className="absolute left-0 top-0 text-[#F02D3B]">■</span>
          Cabal Name
        </Label>
        <Input
          id="cabalName"
          name="cabalName"
          value={formData.cabalName}
          onChange={handleChange}
          placeholder="Enter Cabal Name"
          className="w-full bg-[#1A1A1A] border border-[#00A0B8] p-1 focus:outline-none focus:ring-1 focus:ring-[#00A0B8] text-[#CBE9ED] hover:border-[#00D1E8] focus:border-[#00D1E8] caret-[#00A0B8] animate-caret"
          style={{ boxShadow: 'inset 2px 2px 0 rgba(0,0,0,0.25)' }}
        />
      </div>
      <div className="mb-4">
        <Label htmlFor="cabalLogo" className="block mb-1 relative pl-4">
          Cabal Logo (optional)
        </Label>
        <Input
          id="cabalLogo"
          name="cabalLogo"
          type="file"
          onChange={handleFileChange}
          accept="image/*"
          className="w-full bg-[#1A1A1A] border border-[#00A0B8] p-1 focus:outline-none focus:ring-1 focus:ring-[#00A0B8] text-[#CBE9ED] hover:border-[#00D1E8] focus:border-[#00D1E8]"
          style={{ boxShadow: 'inset 2px 2px 0 rgba(0,0,0,0.25)' }}
        />
        <p className="text-xs text-[#00A0B8] mt-1">Max file size: 5MB</p>
      </div>
      <div className="mb-4">
        <Label htmlFor="cabalDescription" className="block mb-1 relative pl-4">
          <span className="absolute left-0 top-0 text-[#F02D3B]">■</span>
          Cabal Description / Entry Requirements
        </Label>
        <Textarea
          id="cabalDescription"
          name="cabalDescription"
          value={formData.cabalDescription}
          onChange={handleChange}
          placeholder="Enter Cabal Description and Entry Requirements"
          className="w-full bg-[#1A1A1A] border border-[#00A0B8] p-1 focus:outline-none focus:ring-1 focus:ring-[#00A0B8] text-[#CBE9ED] hover:border-[#00D1E8] focus:border-[#00D1E8] caret-[#00A0B8] animate-caret"
          style={{ boxShadow: 'inset 2px 2px 0 rgba(0,0,0,0.25)' }}
        />
      </div>
    </>
  );
};

export default CabalInfoForm;