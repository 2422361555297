import React from 'react';
import { Link } from 'react-router-dom';
import { useTonAddress } from '@tonconnect/ui-react';
import { Button } from "@/components/ui/button";
import TeamRegistrationForm from '../components/team-registration/TeamRegistrationForm';
import { useCabalRegistrations } from '../integrations/supabase/hooks/cabal_registrations';
import AsciiNoiseBackground from '../components/AsciiNoiseBackground';

const TeamMemberRegistration = () => {
  const userAddress = useTonAddress();
  const { data: cabals, isLoading, error } = useCabalRegistrations();

  if (isLoading) {
    return <div className="min-h-screen bg-[#121212] p-8 flex items-center justify-center text-[#CBE9ED]">Loading Cabals...</div>;
  }

  if (error) {
    return <div className="min-h-screen bg-[#121212] p-8 flex items-center justify-center text-[#CBE9ED]">Error loading Cabals: {error.message}</div>;
  }

  return (
    <div className="min-h-screen bg-[#121212] p-8 flex flex-col items-center justify-center relative">
      <AsciiNoiseBackground />
      <div className="w-[460px] bg-[#121212] text-[#CBE9ED] font-mono text-xs p-4 relative z-10"
        style={{
          boxShadow: "rgba(116, 102, 90, 0.157) 4px 4px inset, rgba(0, 0, 0, 0.25) 8px 8px",
          border: "3px solid #00A0B8",
        }}>
        <div className="absolute top-0 left-2 -mt-2 bg-[#024652] px-2 z-10">
          <span className="text-[#F02D3B]">/join_cabal</span>
        </div>
        <h2 className="text-lg font-bold mb-4 text-center">Join an Existing Cabal</h2>
        <TeamRegistrationForm userAddress={userAddress} cabals={cabals} />
      </div>

      <Link to="/">
        <Button
          className="w-[460px] bg-[#1A1A1A] p-2 text-[#00A0B8] hover:bg-[#002227] focus:outline-none focus:ring-1 focus:ring-[#00A0B8] transition-colors rounded-none mt-4"
          style={{
            boxShadow: "2px 2px 0 rgba(0,0,0,0.25)",
          }}
        >
          Back to Main
        </Button>
      </Link>
    </div>
  );
};

export default TeamMemberRegistration;