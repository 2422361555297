import React from 'react';
import {
  RetroTable,
  RetroTableHeader,
  RetroTableBody,
  RetroTableRow,
  RetroTableHead,
  RetroTableCell,
} from "@/components/ui/retro-table";
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/lib/supabase';
import { formatDistanceToNow } from 'date-fns';
import { useWalletAuth } from "@/WalletAuthContext";
import { Card } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { useToast } from "@/components/ui/use-toast";
import { ChevronRight } from "lucide-react";

export function DetailedTradesTable() {
  const { userAddress } = useWalletAuth();
  const { toast } = useToast();

  const { data: trades, isLoading, error } = useQuery({
    queryKey: ['trader-pnl', userAddress],
    queryFn: async () => {
      if (!userAddress) return null;
      
      const { data, error } = await supabase
        .from('trader_pnl')
        .select('*')
        .eq('raw_address', userAddress.toUpperCase())
        .order('last_updated', { ascending: false });

      if (error) {
        toast({
          title: "Error fetching trades",
          description: error.message,
          variant: "destructive",
        });
        throw error;
      }

      return data;
    },
    enabled: !!userAddress,
  });

  if (!userAddress) {
    return (
      <Card className="p-6 bg-[#121212] border-[#2A2A2A]">
        <div className="text-center text-[#CBE9ED]">
          Please connect your wallet to view your trades
        </div>
      </Card>
    );
  }

  if (isLoading) {
    return (
      <Card className="p-6 bg-[#121212] border-[#2A2A2A]">
        <div className="space-y-4">
          {[...Array(5)].map((_, i) => (
            <Skeleton key={i} className="h-12 bg-[#1A1A1A]" />
          ))}
        </div>
      </Card>
    );
  }

  if (error) {
    return (
      <Card className="p-6 bg-[#121212] border-[#2A2A2A]">
        <div className="text-red-500">
          Error loading trades: {error.message}
        </div>
      </Card>
    );
  }

  if (!trades?.length) {
    return (
      <Card className="p-6 bg-[#121212] border-[#2A2A2A]">
        <div className="text-center text-[#CBE9ED]">
          No trades found
        </div>
      </Card>
    );
  }

  const totalPnL = trades.reduce((sum, trade) => sum + Number(trade.token_pnl), 0);
  const totalVolume = trades.reduce((sum, trade) => sum + Number(trade.trading_volume), 0);

  return (
    <div className="space-y-6">
      <Card className="p-6 bg-[#121212] border-[#2A2A2A]">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
          <div className="p-4 bg-[#1A1A1A] rounded-lg border border-[#2A2A2A]">
            <div className="text-[#00A0B8] text-sm mb-1">Total PnL</div>
            <div className={`text-xl font-bold ${totalPnL >= 0 ? 'text-green-500' : 'text-red-500'}`}>
              ${totalPnL.toFixed(2)}
            </div>
          </div>
          <div className="p-4 bg-[#1A1A1A] rounded-lg border border-[#2A2A2A]">
            <div className="text-[#00A0B8] text-sm mb-1">Total Volume</div>
            <div className="text-xl font-bold text-[#00A0B8]">
              ${totalVolume.toFixed(2)}
            </div>
          </div>
          <div className="p-4 bg-[#1A1A1A] rounded-lg border border-[#2A2A2A]">
            <div className="text-[#00A0B8] text-sm mb-1">Total Tokens Traded</div>
            <div className="text-xl font-bold text-[#00A0B8]">
              {trades.length}
            </div>
          </div>
        </div>

        <div className="overflow-x-auto bg-[#121212] border border-[#3465A4]/20 rounded">
          <RetroTable>
            <RetroTableHeader>
              <RetroTableRow>
                <RetroTableHead className="text-[#00A0B8]">Symbol</RetroTableHead>
                <RetroTableHead className="text-[#00A0B8]">PnL</RetroTableHead>
                <RetroTableHead className="text-[#00A0B8]">Position</RetroTableHead>
                <RetroTableHead className="text-[#00A0B8]">Volume</RetroTableHead>
                <RetroTableHead className="text-[#00A0B8]">Last Updated</RetroTableHead>
                <RetroTableHead className="text-[#00A0B8]">Contract</RetroTableHead>
              </RetroTableRow>
            </RetroTableHeader>
            <RetroTableBody>
              {trades.map((trade) => (
                <RetroTableRow key={`${trade.raw_address}-${trade.symbol}`}>
                  <RetroTableCell className="flex items-center gap-1">
                    <ChevronRight className="h-3 w-3 text-[#00A0B8]" />
                    {trade.symbol}
                  </RetroTableCell>
                  <RetroTableCell className={Number(trade.token_pnl) >= 0 ? 'text-green-500' : 'text-red-500'}>
                    ${Math.floor(trade.token_pnl).toLocaleString()}
                  </RetroTableCell>
                  <RetroTableCell>
                    {Number(trade.remaining_position).toFixed(4)}
                  </RetroTableCell>
                  <RetroTableCell>
                    ${Number(trade.trading_volume).toFixed(2)}
                  </RetroTableCell>
                  <RetroTableCell>
                    {formatDistanceToNow(new Date(trade.last_updated), { addSuffix: true })}
                  </RetroTableCell>
                  <RetroTableCell>
                    <a
                      href={`https://tonscan.org/address/${trade.jetton_master}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-[#00A0B8] hover:text-[#00A0B8]/80 transition-colors font-mono"
                    >
                      {`${trade.jetton_master.slice(0, 6)}...${trade.jetton_master.slice(-4)}`}
                    </a>
                  </RetroTableCell>
                </RetroTableRow>
              ))}
            </RetroTableBody>
          </RetroTable>
        </div>
      </Card>
    </div>
  );
}