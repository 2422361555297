import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTonAddress } from '@tonconnect/ui-react';
import { Terminal, Gauge, Database } from 'lucide-react';
import { CabalMemberTable } from '../components/CabalMemberTable';
import { TradesTable } from '../components/TradesTable';
import RetroNotification from '../components/RetroNotification';
import { toast } from 'sonner';

const TeamDashboard = () => {
  const [cabalData, setCabalData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const userAddress = useTonAddress();
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      const proof = localStorage.getItem('tonProof');
      if (!userAddress || !proof) {
        toast.error('Please connect your wallet first');
        navigate('/');
        return;
      }

      try {
        // Fetch cabal data for the connected wallet
        const response = await fetch(`/api/cabal/member/${userAddress}`);
        const data = await response.json();
        setCabalData(data);
      } catch (error) {
        console.error('Error fetching cabal data:', error);
        toast.error('Failed to load team data');
      } finally {
        setIsLoading(false);
      }
    };

    checkAuth();
  }, [userAddress, navigate]);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-[#121212] text-[#00A0B8] font-mono p-4 flex items-center justify-center">
        <div className="animate-pulse">LOADING...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#121212] text-[#CBE9ED] font-mono p-4">
      <div className="max-w-7xl mx-auto">
        <header className="mb-8 border-b border-[#00A0B8] pb-4">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-bold text-[#00A0B8] flex items-center gap-2">
              <Terminal className="w-6 h-6" />
              CABAL_DASHBOARD.exe
            </h1>
            <div className="text-sm">
              <span className="text-[#F02D3B]">CONNECTED: </span>
              {userAddress?.slice(0, 4)}...{userAddress?.slice(-4)}
            </div>
          </div>
        </header>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
          <div className="bg-[#1A1A1A] border border-[#00A0B8] p-4 shadow-[4px_4px_0_rgba(0,0,0,0.25)]">
            <div className="flex items-center gap-2 mb-2">
              <Database className="w-4 h-4" />
              <h2 className="text-[#00A0B8]">CABAL STATUS</h2>
            </div>
            <div className="space-y-2 text-xs">
              <p>NAME: {cabalData?.cabalName || 'N/A'}</p>
              <p>RANK: #{cabalData?.rank || '???'}</p>
              <p>MEMBERS: {cabalData?.memberCount || '0'}/50</p>
            </div>
          </div>

          <div className="bg-[#1A1A1A] border border-[#00A0B8] p-4 shadow-[4px_4px_0_rgba(0,0,0,0.25)]">
            <div className="flex items-center gap-2 mb-2">
              <Gauge className="w-4 h-4" />
              <h2 className="text-[#00A0B8]">PERFORMANCE</h2>
            </div>
            <div className="space-y-2 text-xs">
              <p>TOTAL SCORE: {cabalData?.totalScore?.toLocaleString() || '0'}</p>
              <p>TOTAL PNL: ${cabalData?.totalPnl?.toLocaleString() || '0'}</p>
              <p>DEPLOYMENTS: {cabalData?.deploymentCount || '0'}/4</p>
            </div>
          </div>

          <div className="bg-[#1A1A1A] border border-[#00A0B8] p-4 shadow-[4px_4px_0_rgba(0,0,0,0.25)]">
            <div className="flex items-center gap-2 mb-2">
              <Terminal className="w-4 h-4" />
              <h2 className="text-[#00A0B8]">TOP TOKEN</h2>
            </div>
            <div className="space-y-2 text-xs">
              <p>SYMBOL: {cabalData?.topToken?.symbol || 'N/A'}</p>
              <p>MCAP: ${cabalData?.topToken?.marketCap?.toLocaleString() || '0'}</p>
              <p>24H VOL: ${cabalData?.topToken?.volume24h?.toLocaleString() || '0'}</p>
            </div>
          </div>
        </div>

        <div className="bg-[#1A1A1A] border border-[#00A0B8] p-4 shadow-[4px_4px_0_rgba(0,0,0,0.25)] mb-8">
          <h2 className="text-[#00A0B8] mb-4 flex items-center gap-2">
            <Terminal className="w-4 h-4" />
            RECENT_TRADES.dat
          </h2>
          <TradesTable address={userAddress} />
        </div>

        <div className="bg-[#1A1A1A] border border-[#00A0B8] p-4 shadow-[4px_4px_0_rgba(0,0,0,0.25)] mb-8">
          <h2 className="text-[#00A0B8] mb-4 flex items-center gap-2">
            <Terminal className="w-4 h-4" />
            MEMBER_STATS.dat
          </h2>
          <CabalMemberTable members={cabalData?.members || []} />
        </div>

        <RetroNotification
          variant="info"
          title="SYSTEM MESSAGE"
          className="mb-4"
        >
          Next score update in: {cabalData?.nextUpdate || 'UNKNOWN'}
        </RetroNotification>
      </div>
    </div>
  );
};

export default TeamDashboard;