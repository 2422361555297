import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";

const JoinRequestSuccessModal = ({ isOpen, onClose }) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="bg-[#1A1A2E] border-2 border-[#00A0B8] text-[#CBE9ED] rounded-none">
        <DialogHeader>
          <DialogTitle>Request Submitted Successfully</DialogTitle>
        </DialogHeader>
        <p className="py-4">Your request to join the Cabal has been submitted successfully. The Cabal leader will review your request.</p>
        <DialogFooter>
          <Button
            onClick={onClose}
            className="bg-[#1A1A1A] border border-[#00A0B8] text-[#00A0B8] hover:bg-[#002227] rounded-none"
          >
            Close
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default JoinRequestSuccessModal;