import React from 'react';
import { LeaderboardContainer } from '../components/leaderboard/LeaderboardContainer';
import CompetitionRules from '../components/CompetitionRules';
import AsciiNoiseBackground from '../components/AsciiNoiseBackground';

const PublicDashboard = () => {
  return (
    <div className="min-h-screen bg-[#121212] text-[#CBE9ED] p-4 font-['Press_Start_2P'] relative">
      <AsciiNoiseBackground />
      <div className="max-w-[800px] mx-auto relative z-10">
        <div className="grid grid-cols-1 gap-6">
          <LeaderboardContainer />
          <CompetitionRules />
        </div>
      </div>
    </div>
  );
};

export default PublicDashboard;