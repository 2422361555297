import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";

const CompetitionRules = () => {
  return (
    <Card className="w-full mb-8">
      <CardHeader>
        <CardTitle>Competition Rules and Info</CardTitle>
      </CardHeader>
      <CardContent>
        <Accordion type="single" collapsible>
          <AccordionItem value="item-1">
            <AccordionTrigger>Summarized Rules</AccordionTrigger>
            <AccordionContent>
              <ul className="list-disc list-inside">
                <li>Form a Cabal of 10-50 members</li>
                <li>Deploy up to 3 memecoins</li>
                <li>Trade and increase your Cabal's score</li>
                <li>Top Cabals share the prize pool</li>
              </ul>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-2">
            <AccordionTrigger>Cabal Formation</AccordionTrigger>
            <AccordionContent>
              <p>Cabals must have at least 10 members and can have up to 50 members. Each Cabal is led by a Cabal Leader who is responsible for deploying memecoins.</p>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-3">
            <AccordionTrigger>Scoring Rules</AccordionTrigger>
            <AccordionContent>
              <p>Individual Score = (Individual PnL * 0.7) + (Individual Trading Volume * 0.3) * Token Multiplier * Wallet Engagement Multiplier</p>
              <p>Team Score = Average of the top 10 individual scores in the team</p>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-4">
            <AccordionTrigger>Memecoin Visibility Rules</AccordionTrigger>
            <AccordionContent>
              <p>Memecoins become visible to other Cabals when they reach certain trading volume or holder thresholds. Strategic deployment and initial trading can keep your memecoin hidden longer.</p>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-5">
            <AccordionTrigger>Bonus Features</AccordionTrigger>
            <AccordionContent>
              <p>"First Trade" bonus: Extra points for being among the first to trade a newly revealed memecoin.</p>
              <p>"Unknown Memecoins" tracking: The system tracks undiscovered memecoins, providing hints as they near reveal thresholds.</p>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
        <div className="mt-4">
          <p>Competition ends in: [Countdown Timer]</p>
          <a href="#" className="text-blue-600 hover:underline">View Full Rules</a>
        </div>
      </CardContent>
    </Card>
  );
};

export default CompetitionRules;