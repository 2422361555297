import React, { useEffect, useRef, memo } from "react";
import p5 from "p5";
import { generateAndStoreAsciiNoiseParams } from "../utils/asciiNoiseParams";

// Pre-compute character array and cache it
const ANIMATION_CHARS = ["C", "A", "B", "A", "L", "A", "R", "E", "N", "A", "T", "O", "N"];
const CHAR_LENGTH = ANIMATION_CHARS.length - 1;

const AsciiNoiseBackground = memo(() => {
  const canvasRef = useRef(null);
  const p5InstanceRef = useRef(null);

  useEffect(() => {
    const params = generateAndStoreAsciiNoiseParams();
    p5.disableFriendlyErrors = true;

    const Sketch = (p) => {
      // Cached variables
      let scale = 1;
      const fontSize = 14;
      let step = Math.max(4, 12 * scale);
      let t = 0;
      const noiseScale = (0.0021 + params.noiseVar) * (1 / scale);
      const hsbMode = params.hsbMode;
      let fillCol;
      const colorType = Math.random() < 0.04 ? "Gradient" : "Normal";
      
      // Pre-calculated values
      let width, height, rows, cols;
      const charMap = new Array(127).fill(0).map((_, i) => {
        const charIndex = Math.floor(p.map(i, params.charStart, 126, 0, CHAR_LENGTH));
        return ANIMATION_CHARS[charIndex] || ANIMATION_CHARS[0];
      });

      // Dynamic color palette generation for more vibrancy
      const generateColorPalette = () => {
        return [
          p.color(255, 100, 100, 200), // Red with alpha
          p.color(100, 255, 100, 200), // Green with alpha
          p.color(100, 100, 255, 200), // Blue with alpha
          p.color(255, 255, 100, 200), // Yellow with alpha
          p.color(255, 100, 255, 200), // Magenta with alpha
          p.color(100, 255, 255, 200), // Cyan with alpha
          p.color(255, 150, 50, 200),  // Orange with alpha
          p.color(150, 50, 255, 200)   // Purple with alpha
        ];
      };

      let colorPalette;

      p.setup = () => {
        width = window.innerWidth;
        height = window.innerHeight;
        p.createCanvas(width, height);
        p.noiseSeed(params.seed);
        p.randomSeed(params.seed);

        // Pre-calculate grid dimensions
        rows = Math.ceil(height / step);
        cols = Math.ceil(width / step);

        if (hsbMode) {
          p.colorMode(p.HSB);
          fillCol = p.color(Math.round(p.random(360)), 100, 100, 0.8);
        } else {
          p.colorMode(p.RGB);
          colorPalette = generateColorPalette();
        }

        p.noStroke();
        p.textSize(fontSize / 1.3);
        p.textStyle(p.NORMAL);
        p.frameRate(30); // Reduced frame rate for better performance
      };

      // Create offscreen buffer for background - cached
      let bgBuffer;
      const setupBuffer = () => {
        if (bgBuffer) bgBuffer.remove();
        bgBuffer = p.createGraphics(width, height);
        bgBuffer.noStroke();
        bgBuffer.textSize(fontSize / 1.3);
        bgBuffer.textStyle(p.NORMAL);
        bgBuffer.fill(100, 50); // Semi-transparent for better effect
        
        // Pre-render background characters
        for (let y = 0; y < rows; y++) {
          for (let x = 0; x < cols; x++) {
            bgBuffer.text(params.bgChar, x * step, y * step);
          }
        }
      };

      p.draw = () => {
        if (!bgBuffer) setupBuffer();

        // Use semi-transparent background for fade effect
        if (hsbMode) {
          p.background(0, 0, 0, 0.1);
        } else {
          p.background(0, 10);
        }

        // Draw background from buffer
        p.image(bgBuffer, 0, 0);

        t += params.timeFactor * noiseScale;

        // Batch process noise calculations
        for (let y = 0; y < rows; y++) {
          const yPos = y * step;
          for (let x = 0; x < cols; x++) {
            const xPos = x * step;
            const i = p.noise(xPos * noiseScale * 1.5, yPos * noiseScale * 1.5, t);

            if (i > 0.3 && i < 0.61 && Math.abs(i * 10 % 1 - 0.1) < 0.01) {
              if (hsbMode) {
                p.fill(fillCol);
              } else if (colorType === "Gradient") {
                const hue = (xPos / width * 360 + t * 50) % 360;
                p.fill(p.color(hue, 100, 100, 0.8));
              } else {
                const colorIndex = Math.floor(p.map(i, 0.3, 0.61, 0, colorPalette.length - 1));
                p.fill(colorPalette[colorIndex]);
              }

              const charIndex = Math.floor(p.map(xPos, 0, width, params.charStart, 126));
              p.text(charMap[charIndex], xPos, yPos);
            }
          }
        }
      };

      p.windowResized = () => {
        width = window.innerWidth;
        height = window.innerHeight;
        p.resizeCanvas(width, height);
        rows = Math.ceil(height / step);
        cols = Math.ceil(width / step);
        if (bgBuffer) {
          bgBuffer.remove();
          bgBuffer = null;
        }
      };
    };

    // Create new p5 instance and store reference
    if (canvasRef.current && !p5InstanceRef.current) {
      p5InstanceRef.current = new p5(Sketch, canvasRef.current);
    }

    // Cleanup function
    return () => {
      if (p5InstanceRef.current) {
        p5InstanceRef.current.remove();
        p5InstanceRef.current = null;
      }
    };
  }, []); // Empty dependency array since we want this to run once

  return (
    <div
      ref={canvasRef}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 0,
        pointerEvents: "none",
      }}
      aria-hidden="true"
    />
  );
});

AsciiNoiseBackground.displayName = 'AsciiNoiseBackground';

export default AsciiNoiseBackground;