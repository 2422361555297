import TonWeb from 'tonweb';

export function toRawAddress(address: string): string {
  try {
    if (!address) return '';
    
    // Convert to raw format (non-bounceable, non-friendly)
    const tonWebAddress = new TonWeb.utils.Address(address);
    return tonWebAddress.toString(false, false, false);
  } catch (error) {
    console.error('Error converting address:', error);
    return address;
  }
}

// Keep friendly address conversion for other use cases
export function toFriendlyAddress(address: string): string {
  try {
    if (!address) return '';
    
    // If already in friendly format with EQ prefix, return as is
    if (address.startsWith('EQ')) {
      return address;
    }
    
    // If it starts with UQ, convert it to EQ
    if (address.startsWith('UQ')) {
      const rawAddress = address.slice(2); // Remove UQ prefix
      const tonWebAddress = new TonWeb.utils.Address(`0:${rawAddress}`);
      return tonWebAddress.toString(true, true, false); // friendly, bounceable, mainnet
    }

    // For raw/base64 addresses
    const tonWebAddress = new TonWeb.utils.Address(address);
    return tonWebAddress.toString(true, true, false); // friendly, bounceable, mainnet
  } catch (error) {
    console.error('Error converting address:', error);
    return address;
  }
}
