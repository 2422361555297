import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import { toRawAddress } from "@/utils/addressUtils";
import { calculateMultiplier } from "@/utils/multiplierUtils";

export function useLeaderboardData(activeTab: string) {
  return useQuery({
    queryKey: ["leaderboardData", activeTab],
    queryFn: async () => {
      try {
        if (activeTab === "best-snipers") {
          const { data: snipers, error: snipersError } = await supabase
            .rpc('get_best_snipers');

          if (snipersError) throw snipersError;

          return snipers.map((sniper) => ({
            id: sniper.trader_address,
            rank: sniper.rank,
            nickname: sniper.nickname,
            score: sniper.individual_score?.toFixed(2) || "0.00",
            volume: `$${Math.floor(sniper.total_volume || 0).toLocaleString()}`
          }));
        } else if (activeTab === "meme-pool") {
          const { data: memecoins, error: memecoinError } = await supabase
            .rpc('get_memes');

          if (memecoinError) throw memecoinError;

          return (memecoins || []).map((coin, index) => ({
            id: index,
            rank: index + 1,
            symbol: coin.symbol || "Unknown",
            marketcap: `$${Math.floor(coin.market_cap || 0).toLocaleString()}`,
            volume: `$${Math.floor(coin.total_volume || 0).toLocaleString()}`,
            multiplier: `${coin.multiplier}x`,
          }));
        } else {
          const { data: cabalScores, error: cabalError } = await supabase
            .rpc('get_cabals');

          if (cabalError) throw cabalError;

          return (cabalScores || []).map((score) => ({
            id: score.rank,
            rank: score.rank,
            cabal: score.cabal_name,
            score: Math.floor(score.avg_top10_score || 0),
            totalmcap: `$${Math.floor(score.total_mcap || 0).toLocaleString()}`,
            bestmcap: `$${Math.floor(score.top_memecoin_mcap || 0).toLocaleString()}`,
            prizeshare: score.prize_share ? `${(score.prize_share * 100).toFixed(1)}%` : "0%",
          }));
        }
      } catch (error) {
        console.error("Error fetching leaderboard data:", error);
        throw error;
      }
    },
    refetchInterval: 30000,
  });
}