import React from 'react';
import { useManageJoinRequest } from '../../hooks/useLeaderDashboard';
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useWalletAuth } from '../../WalletAuthContext';

const JoinRequestsSection = ({ requests = [] }) => {
  const { userAddress } = useWalletAuth();
  const { mutate: manageRequest, isLoading } = useManageJoinRequest();

  const handleRequest = (requestId, action) => {
    manageRequest({ 
      address: userAddress,
      requestId,
      action
    });
  };

  return (
    <ScrollArea className="h-[400px]">
      <div className="space-y-4">
        {requests.map((request) => (
          <div 
            key={request.id}
            className="p-4 bg-[#121212] border border-[#00A0B8] rounded"
          >
            <div className="flex flex-col gap-2">
              <div>
                <p className="text-[#CBE9ED] font-mono text-xs mb-1">
                  From: {request.address}
                </p>
                <p className="text-[#CBE9ED] text-xs mb-2">{request.message}</p>
                <p className="text-[#CBE9ED] text-xs opacity-60">
                  {new Date(request.created_at).toLocaleString()}
                </p>
              </div>
              <div className="flex gap-2 mt-2">
                <Button
                  onClick={() => handleRequest(request.id, 'approve')}
                  disabled={isLoading}
                  size="sm"
                  className="h-8 px-3 text-xs bg-[#1A1A1A] border border-green-500 text-green-500 hover:bg-green-900/20"
                >
                  Accept
                </Button>
                <Button
                  onClick={() => handleRequest(request.id, 'reject')}
                  disabled={isLoading}
                  size="sm"
                  className="h-8 px-3 text-xs bg-[#1A1A1A] border border-red-500 text-red-500 hover:bg-red-900/20"
                >
                  Reject
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </ScrollArea>
  );
};

export default JoinRequestsSection;