import React from 'react';
import { useWalletAuth } from "../WalletAuthContext";
import { DetailedTradesTable } from "../components/DetailedTradesTable";
import { Card } from "@/components/ui/card";
import { DatabaseIcon } from "lucide-react";

const PersonalTrades = () => {
  const { userAddress, isWalletConnected } = useWalletAuth();

  if (!isWalletConnected) {
    return (
      <div className="container mx-auto px-4 py-8">
        <Card className="p-6 bg-[#121212] border-[#2A2A2A]">
          <div className="text-center">
            <h2 className="text-xl font-bold text-[#00A0B8] mb-4">Connect Wallet</h2>
            <p className="text-[#CBE9ED]">Please connect your wallet to view your trades</p>
          </div>
        </Card>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex items-center gap-2 mb-6">
        <DatabaseIcon className="h-6 w-6 text-[#00A0B8]" />
        <h1 className="text-2xl font-bold text-[#00A0B8]">Your Trading Performance</h1>
      </div>
      
      <DetailedTradesTable />
    </div>
  );
};

export default PersonalTrades;