import React from 'react';
import { Card } from "@/components/ui/card";
import { TraderCabalData } from '@/hooks/useTraderCabal';
import { Terminal, Users, TrendingUp } from "lucide-react";
import { formatDistanceToNow } from 'date-fns';
import {
  RetroTable,
  RetroTableHeader,
  RetroTableBody,
  RetroTableRow,
  RetroTableHead,
  RetroTableCell,
} from "@/components/ui/retro-table";

interface TraderCabalViewProps {
  data: TraderCabalData;
}

export const TraderCabalView: React.FC<TraderCabalViewProps> = ({ data }) => {
  if (!data.is_member) {
    return (
      <Card className="bg-[#1A1A1A] border-[#00A0B8] p-6">
        <div className="text-center text-[#CBE9ED]">
          <h2 className="text-xl font-bold text-[#00A0B8] mb-4">Access Denied</h2>
          <p>{data.message || "You are not a member of this cabal."}</p>
        </div>
      </Card>
    );
  }

  return (
    <div className="space-y-6">
      <Card className="bg-[#1A1A1A] border-[#00A0B8] p-6">
        <div className="flex items-center justify-between mb-6">
          <div>
            <h2 className="text-2xl font-bold text-[#00A0B8]">{data.cabal?.name}</h2>
            <p className="text-[#CBE9ED] mt-1">{data.cabal?.description}</p>
          </div>
          {data.cabal?.image && (
            <img 
              src={data.cabal.image} 
              alt={data.cabal.name} 
              className="w-16 h-16 rounded-full"
            />
          )}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="bg-[#121212] p-4 rounded-lg">
            <div className="flex items-center gap-2 mb-2">
              <Users className="w-4 h-4 text-[#00A0B8]" />
              <h3 className="text-[#00A0B8]">Members</h3>
            </div>
            <p className="text-xl text-[#CBE9ED]">
              {data.cabal?.members_count}/{100 - (data.cabal?.slots_available || 0)}
            </p>
          </div>

          <div className="bg-[#121212] p-4 rounded-lg">
            <div className="flex items-center gap-2 mb-2">
              <TrendingUp className="w-4 h-4 text-[#00A0B8]" />
              <h3 className="text-[#00A0B8]">Total Volume</h3>
            </div>
            <p className="text-xl text-[#CBE9ED]">
              ${data.cabal_pnl?.total_volume.toLocaleString()}
            </p>
          </div>

          <div className="bg-[#121212] p-4 rounded-lg">
            <div className="flex items-center gap-2 mb-2">
              <Terminal className="w-4 h-4 text-[#00A0B8]" />
              <h3 className="text-[#00A0B8]">Total PnL</h3>
            </div>
            <p className="text-xl text-[#CBE9ED]">
              ${(data.cabal_pnl?.total_own_pnl + data.cabal_pnl?.total_others_pnl).toLocaleString()}
            </p>
          </div>
        </div>
      </Card>

      {data.cabal_trades && data.cabal_trades.length > 0 && (
        <Card className="bg-[#1A1A1A] border-[#00A0B8] p-6">
          <h3 className="text-xl font-bold text-[#00A0B8] mb-4">Recent Trades</h3>
          <RetroTable>
            <RetroTableHeader>
              <RetroTableRow>
                <RetroTableHead>Trader</RetroTableHead>
                <RetroTableHead>Symbol</RetroTableHead>
                <RetroTableHead>Side</RetroTableHead>
                <RetroTableHead>Amount</RetroTableHead>
                <RetroTableHead>Price</RetroTableHead>
                <RetroTableHead>Time</RetroTableHead>
              </RetroTableRow>
            </RetroTableHeader>
            <RetroTableBody>
              {data.cabal_trades.map((trade, index) => (
                <RetroTableRow key={index}>
                  <RetroTableCell>{trade.trader_address.slice(0, 6)}...{trade.trader_address.slice(-4)}</RetroTableCell>
                  <RetroTableCell>{trade.symbol}</RetroTableCell>
                  <RetroTableCell className={trade.side === 'buy' ? 'text-green-500' : 'text-red-500'}>
                    {trade.side.toUpperCase()}
                  </RetroTableCell>
                  <RetroTableCell>{trade.amount}</RetroTableCell>
                  <RetroTableCell>${trade.price}</RetroTableCell>
                  <RetroTableCell>{formatDistanceToNow(new Date(trade.created_at), { addSuffix: true })}</RetroTableCell>
                </RetroTableRow>
              ))}
            </RetroTableBody>
          </RetroTable>
        </Card>
      )}

      {data.top_traders && data.top_traders.length > 0 && (
        <Card className="bg-[#1A1A1A] border-[#00A0B8] p-6">
          <h3 className="text-xl font-bold text-[#00A0B8] mb-4">Top Traders</h3>
          <RetroTable>
            <RetroTableHeader>
              <RetroTableRow>
                <RetroTableHead>Rank</RetroTableHead>
                <RetroTableHead>Trader</RetroTableHead>
                <RetroTableHead>Score</RetroTableHead>
                <RetroTableHead>Volume</RetroTableHead>
              </RetroTableRow>
            </RetroTableHeader>
            <RetroTableBody>
              {data.top_traders.map((trader, index) => (
                <RetroTableRow key={index}>
                  <RetroTableCell>#{index + 1}</RetroTableCell>
                  <RetroTableCell>{trader.nickname || trader.address.slice(0, 6)}...{trader.address.slice(-4)}</RetroTableCell>
                  <RetroTableCell>{Math.floor(trader.individual_score).toLocaleString()}</RetroTableCell>
                  <RetroTableCell>${Math.floor(trader.total_volume).toLocaleString()}</RetroTableCell>
                </RetroTableRow>
              ))}
            </RetroTableBody>
          </RetroTable>
        </Card>
      )}
    </div>
  );
};