import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { useWalletAuth } from "../WalletAuthContext";
import { useWalletBalance } from "../hooks/useWalletBalance";
import { LogOut, Terminal, Swords } from "lucide-react";
import { useCabalMembership } from "../hooks/useCabalMembership";
import { useNavItems } from "../nav-items";

const RetroNavbarComponent = () => {
  const [tonConnectUI] = useTonConnectUI();
  const { userAddress, isWalletConnected } = useWalletAuth();
  const walletBalance = useWalletBalance(userAddress);
  const location = useLocation();
  const { isCabalMember, cabalId } = useCabalMembership(userAddress);
  const navItems = useNavItems();

  const truncatedAddress = userAddress
    ? `${userAddress.slice(0, 4)}...${userAddress.slice(-4)}`
    : null;

  const handleConnect = async () => {
    try {
      await tonConnectUI.openModal();
    } catch (error) {
      console.error("Error opening TonConnect modal:", error);
    }
  };

  const handleDisconnect = async () => {
    try {
      await tonConnectUI.disconnect();
    } catch (error) {
      console.error("Error disconnecting wallet:", error);
    }
  };

  const navLinkClass = (path) =>
    `transition-colors duration-200 ${
      location.pathname === path
        ? "text-[#00A0B8] border-b-2 border-[#00A0B8]"
        : "text-[#D4D0D4] hover:text-[#00A0B8] hover:border-b-2 hover:border-[#00A0B8]"
    }`;

  return (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-[#111111] border-b border-[#2A2A2A]">
      <div className="container mx-auto px-4 h-[20px]">
        <div className="flex items-center justify-between h-full">
          <div className="flex items-center space-x-8">
            <Link to="/" className="text-[#00A0B8] font-mono flex items-center gap-2">
              <Terminal className="w-4 h-4" />
              ./cabal_arena.sh
            </Link>
            {navItems.map((item) => (
              <Link
                key={item.to}
                to={item.to}
                className={navLinkClass(item.to)}
              >
                <span className="flex items-center gap-2 font-mono">
                  {item.title === "Battleground" ? (
                    <Swords className="w-4 h-4 rotate-45" />
                  ) : (
                    item.icon
                  )}
                  {item.title}
                </span>
              </Link>
            ))}
          </div>
          <div className="flex items-center space-x-6">
            {isWalletConnected ? (
              <>
                <span className="text-[#D4D0D4] font-mono">
                  {walletBalance.toFixed(2)} TON
                </span>
                <span className="text-[#D4D0D4] font-mono">
                  {truncatedAddress}
                </span>
                <button
                  onClick={handleDisconnect}
                  className="flex items-center space-x-2 text-[#F02D3B] hover:text-[#FF4D4D] transition-colors duration-200 cursor-pointer font-mono"
                >
                  <LogOut className="w-4 h-4" />
                  <span>Disconnect</span>
                </button>
              </>
            ) : (
              <button
                onClick={handleConnect}
                className="text-[#FFA500] hover:text-[#D4D0D4] transition-colors duration-200 cursor-pointer font-mono"
              >
                Connect Wallet
              </button>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default RetroNavbarComponent;