import React from 'react';
import { useWalletAuth } from '../WalletAuthContext';
import { useTraderCabal } from '../hooks/useTraderCabal';
import { TraderCabalView } from '../components/TraderCabalView';
import ReturnToLandingButton from '../components/ReturnToLandingButton';
import AsciiNoiseBackground from '../components/AsciiNoiseBackground';
import { Terminal, Lock } from "lucide-react";
import { useCabalMembership } from '../hooks/useCabalMembership';
import { toast } from 'sonner';
import { Card } from "@/components/ui/card";

const CabalStats = () => {
  const { userAddress } = useWalletAuth();
  const { isCabalMember, isCabalLeader } = useCabalMembership(userAddress);
  const { data: cabalData, isLoading, error } = useTraderCabal(userAddress);

  if (!userAddress) {
    return (
      <div className="min-h-screen bg-[#121212] p-8 relative">
        <AsciiNoiseBackground />
        <div className="container mx-auto px-4 py-8 relative z-10">
          <div className="text-center">
            <h2 className="text-xl font-bold text-[#00A0B8] font-mono">Connect Wallet</h2>
            <p className="text-[#CBE9ED] font-mono mt-2">Please connect your wallet to view cabal stats</p>
          </div>
        </div>
      </div>
    );
  }

  if (!isCabalMember && !isCabalLeader) {
    return (
      <div className="min-h-screen bg-[#121212] p-8 relative">
        <AsciiNoiseBackground />
        <div className="container mx-auto px-4 py-8 relative z-10">
          <Card className="max-w-md mx-auto p-6 bg-[#1A1A1A] border-[#00A0B8]">
            <div className="text-center">
              <Lock className="w-12 h-12 text-[#00A0B8] mx-auto mb-4" />
              <h2 className="text-xl font-bold text-[#00A0B8] font-mono mb-2">Access Restricted</h2>
              <p className="text-[#CBE9ED] font-mono">
                Only cabal members and leaders can view team stats.
              </p>
            </div>
          </Card>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="min-h-screen bg-[#121212] text-[#00A0B8] p-8 flex items-center justify-center font-mono">
        <pre className="animate-pulse">
          {`
▒▒▒ LOADING CABAL STATS ▒▒▒
    ░░░░░░░░░░░░░
    ░░░░░░░░░░░░░
    ░░░░░░░░░░░░░
          `}
        </pre>
      </div>
    );
  }

  if (error) {
    toast.error("Failed to load cabal stats");
    return (
      <div className="min-h-screen bg-[#121212] p-8 relative">
        <AsciiNoiseBackground />
        <div className="container mx-auto px-4 py-8 relative z-10">
          <div className="text-center">
            <h2 className="text-xl font-bold text-[#00A0B8] font-mono">ERROR LOADING DATA</h2>
            <p className="text-[#CBE9ED] font-mono mt-2">Please try again later</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#121212] p-8 relative">
      <AsciiNoiseBackground />
      <div className="max-w-7xl mx-auto space-y-8 relative z-10">
        <div className="flex items-center justify-between mb-8">
          <div className="flex items-center gap-2">
            <Terminal className="h-6 w-6 text-[#00A0B8]" />
            <h1 className="text-2xl font-bold text-[#00A0B8] font-mono">CABAL STATS</h1>
          </div>
        </div>

        <TraderCabalView data={cabalData} />
        
        <ReturnToLandingButton />
      </div>
    </div>
  );
};

export default CabalStats;