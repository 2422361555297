import { Toaster } from "sonner";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { useWalletAuth, WalletAuthProvider } from "./WalletAuthContext";
import { useCabalMembership } from "./hooks/useCabalMembership";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";

// Components
import AsciiNoiseBackground from "./components/AsciiNoiseBackground";
import RetroNavbarComponent from "./components/RetroNavbarComponent";

// Pages
import LandingPage from "./pages/LandingPage";
import CabalLeaderRegistration from "./pages/CabalLeaderRegistration";
import TeamMemberRegistration from "./pages/TeamMemberRegistration";
import PublicDashboard from "./pages/PublicDashboard";
import TeamDashboard from "./pages/TeamDashboard";
import PersonalTrades from "./pages/PersonalTrades";
import CabalLeaderPanel from "./pages/CabalLeaderPanel";

// Config
import { useNavItems } from "./nav-items";

const TON_CONNECT_MANIFEST_URL = "https://cabal-arena.fun/tonconnect-manifest.json";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000,
      retry: 1,
    },
  },
});

const ProtectedRoute = ({ children }) => {
  const { userAddress } = useWalletAuth();
  const { isCabalLeader } = useCabalMembership(userAddress);

  if (!isCabalLeader) {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};

const AppContent = () => {
  const navItems = useNavItems();

  return (
    <div className="min-h-screen relative">
      <AsciiNoiseBackground />
      <div className="relative z-10">
        <RetroNavbarComponent />
        <main className={"pt-[20px]"}>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route
              path="/register-leader"
              element={<CabalLeaderRegistration />}
            />
            <Route
              path="/register-member"
              element={<TeamMemberRegistration />}
            />
            <Route path="/dashboard" element={<PublicDashboard />} />
            <Route path="/team-dashboard" element={<TeamDashboard />} />
            <Route path="/trades" element={<PersonalTrades />} />
            <Route
              path="/leader-panel"
              element={
                <ProtectedRoute>
                  <CabalLeaderPanel />
                </ProtectedRoute>
              }
            />
            {navItems.map(({ to, page }) => (
              <Route key={to} path={to} element={page} />
            ))}
          </Routes>
        </main>
      </div>
      <Analytics />
      <SpeedInsights />
    </div>
  );
};

const App = () => (
  <QueryClientProvider client={queryClient}>
    <TooltipProvider>
      <TonConnectUIProvider manifestUrl={TON_CONNECT_MANIFEST_URL}>
        <WalletAuthProvider>
          <Toaster position="top-right" />
          <BrowserRouter>
            <AppContent />
          </BrowserRouter>
        </WalletAuthProvider>
      </TonConnectUIProvider>
    </TooltipProvider>
  </QueryClientProvider>
);

export default App;
