import { useQuery } from '@tanstack/react-query';
import { dbFunctions, Trade } from '../lib/db-functions';

export function useTrades(address?: string) {
  return useQuery({
    queryKey: ['trades', address],
    queryFn: () => dbFunctions.fetchTrades(address),
    enabled: !!address,
    refetchInterval: 30000 // Refetch every 30 seconds
  });
}