import React, { useState } from 'react';
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { supabase } from '../../integrations/supabase/supabase';
import JoinCabalSlider from '../JoinCabalSlider';
import { JoinRequestDialog } from '../JoinRequestDialog';
import JoinRequestSuccessModal from './JoinRequestSuccessModal';
import { useCabalsList } from '../../hooks/useCabalsList';

const TeamRegistrationForm = ({ userAddress }) => {
  const { toast } = useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [selectedCabal, setSelectedCabal] = useState(null);
  const [invitationCode, setInvitationCode] = useState('');

  const { data: cabals, isLoading, error } = useCabalsList();

  const handleJoinRequest = (cabal) => {
    if (!userAddress) {
      toast({
        title: "Wallet Not Connected",
        description: "Please connect your wallet to request joining a Cabal.",
        variant: "destructive",
      });
      return;
    }
    setSelectedCabal(cabal);
    setIsModalOpen(true);
  };

  const handleSubmitRequest = async (message) => {
    try {
      if (!selectedCabal?.id || !userAddress) {
        throw new Error("Missing required information");
      }

      const { data: existingRequest } = await supabase
        .from('cabal_join_requests')
        .select('*')
        .eq('cabal_id', selectedCabal.id)
        .eq('requester_wallet_address', userAddress)
        .single();

      if (existingRequest) {
        toast({
          title: "Request Exists",
          description: "You have already submitted a request to join this Cabal",
          variant: "destructive",
        });
        return;
      }

      const { error: joinError } = await supabase
        .from('cabal_join_requests')
        .insert({
          cabal_id: selectedCabal.id,
          requester_wallet_address: userAddress,
          message: message,
          status: 'pending'
        });

      if (joinError) throw joinError;

      setIsModalOpen(false);
      setIsSuccessModalOpen(true);
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      });
    }
  };

  const handleInviteCodeSubmit = async () => {
    try {
      if (!invitationCode.trim()) {
        toast({
          title: "Invalid Code",
          description: "Please enter an invitation code",
          variant: "destructive",
        });
        return;
      }

      const { data: teamData, error: teamError } = await supabase
        .from('teams')
        .select('*')
        .eq('invite_code', invitationCode)
        .single();

      if (teamError || !teamData) {
        toast({
          title: "Invalid Code",
          description: "The invitation code is not valid",
          variant: "destructive",
        });
        return;
      }

      const { error: memberError } = await supabase
        .from('cabal_join_requests')
        .insert({
          cabal_id: teamData.id,
          requester_wallet_address: userAddress,
          raw_address: userAddress,
          status: 'pending'
        });

      if (memberError) throw memberError;

      toast({
        title: "Success",
        description: "Your join request has been submitted successfully!",
      });
      setIsSuccessModalOpen(true);

    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      });
    }
  };

  return (
    <>
      <div className="mb-4">
        <JoinCabalSlider 
          cabals={cabals || []} 
          onJoinRequest={handleJoinRequest}
          isLoading={isLoading}
        />
      </div>
      <div className="flex mb-4 px-4">
        <Input
          type="text"
          placeholder="Invitation code"
          value={invitationCode}
          onChange={(e) => setInvitationCode(e.target.value)}
          className="flex-grow bg-[#1A1A1A] border border-[#00A0B8] p-1 focus:outline-none focus:ring-1 focus:ring-[#00A0B8] text-[#CBE9ED] text-xs rounded-none"
          style={{
            boxShadow: "inset 2px 2px 0 rgba(0,0,0,0.25)",
          }}
        />
        <Button
          onClick={handleInviteCodeSubmit}
          className="ml-2 bg-[#1A1A1A] border border-[#00A0B8] p-1 text-[#00A0B8] hover:bg-[#002227] focus:outline-none focus:ring-1 focus:ring-[#00A0B8] transition-colors text-xs rounded-none"
        >Submit
        </Button>
      </div>

      <JoinRequestDialog 
        open={isModalOpen} 
        onOpenChange={setIsModalOpen}
        onSubmit={handleSubmitRequest}
      />

      <JoinRequestSuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => setIsSuccessModalOpen(false)}
      />
    </>
  );
};

export default TeamRegistrationForm;