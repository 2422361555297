import React, { useState, useEffect } from 'react';
import { useToast } from "@/components/ui/use-toast";
import { useWalletBalance } from '../hooks/useWalletBalance';
import CabalRegistrationForm from '../components/CabalRegistrationForm';
import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import AsciiNoiseBackground from '../components/AsciiNoiseBackground';

const CabalLeaderRegistration = () => {
  const [tonConnectUI] = useTonConnectUI();
  const userAddress = useTonAddress();
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const walletBalance = useWalletBalance(userAddress);

  useEffect(() => {
    const checkWalletConnection = async () => {
      const isConnected = await tonConnectUI.connected;
      setIsWalletConnected(isConnected);
    };
    checkWalletConnection();

    const unsubscribe = tonConnectUI.onStatusChange(async (wallet) => {
      setIsWalletConnected(!!wallet);
    });

    return () => {
      unsubscribe();
    };
  }, [tonConnectUI]);

  return (
    <div className="min-h-screen bg-[#121212] p-8 flex flex-col items-center justify-center relative">
      <AsciiNoiseBackground />
      <div className="w-[460px] bg-[#121212] text-[#CBE9ED] font-mono text-xs p-4 relative z-10" style={{
        boxShadow: 'rgba(116, 102, 90, 0.157) 4px 4px inset, rgba(0, 0, 0, 0.25) 8px 8px',
        border: '3px solid #00A0B8',
      }}>
        <div className="absolute top-0 left-2 -mt-2 bg-[#024652] px-2 z-10">
          <span className="text-[#F02D3B]">/register_cabal</span>
        </div>
        <h2 className="text-lg font-bold mb-4 text-center">Cabal Leader Registration</h2>
        <CabalRegistrationForm
          isWalletConnected={!!userAddress}
          walletBalance={walletBalance}
        />
      </div>
    </div>
  );
};

export default CabalLeaderRegistration;