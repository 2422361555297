import { HomeIcon, BarChartIcon, UserIcon, DatabaseIcon, CommandIcon } from "lucide-react";
import PublicDashboard from "./pages/PublicDashboard";
import CabalStats from "./pages/CabalStats";
import PersonalTrades from "./pages/PersonalTrades";
import CabalLeaderPanel from "./pages/CabalLeaderPanel";
import { useWalletAuth } from "./WalletAuthContext";
import { useCabalMembership } from "./hooks/useCabalMembership";

export const useNavItems = () => {
  const { userAddress, isWalletConnected } = useWalletAuth();
  const { isCabalLeader, isCabalMember } = useCabalMembership(userAddress);

  // Base items that are always shown
  const baseItems = [
    {
      title: "Battleground",
      to: "/dashboard",
      icon: <HomeIcon className="h-4 w-4" />,
      page: <PublicDashboard />,
    }
  ];

  // Items shown only when wallet is connected and user is a cabal member or leader
  const authenticatedItems = (isCabalMember || isCabalLeader) ? [
    {
      title: "Stats",
      to: "/stats",
      icon: <BarChartIcon className="h-4 w-4" />,
      page: <CabalStats />,
    }
  ] : [];

  // Conditional item based on cabal leader status
  const conditionalItem = isCabalLeader ? {
    title: "My Cabal",
    to: "/leader-panel",
    icon: <CommandIcon className="h-4 w-4" />,
    page: <CabalLeaderPanel />,
  } : {
    title: "Your Trades",
    to: "/trades",
    icon: <DatabaseIcon className="h-4 w-4" />,
    page: <PersonalTrades />,
  };

  // Return different sets of items based on wallet connection
  if (!isWalletConnected) {
    return baseItems;
  }

  return [...baseItems, ...authenticatedItems, conditionalItem];
};