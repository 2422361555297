import React, { useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { supabase } from '../integrations/supabase/supabase';
import { useQuery } from '@tanstack/react-query';
import { useToast } from "@/components/ui/use-toast";

export const JoinCabalSlider = ({ cabals = [], onJoinRequest, isLoading }) => {
  const { toast } = useToast();
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = cabals.length || 1;
  const [imageError, setImageError] = useState({});

  const nextPage = () => setCurrentPage(prev => (prev % totalPages) + 1);
  const prevPage = () => setCurrentPage(prev => ((prev - 2 + totalPages) % totalPages) + 1);

  const currentCabal = cabals[currentPage - 1] || {
    cabal_name: isLoading ? 'Loading...' : 'No cabals available',
    cabal_description: isLoading ? 'Loading cabal data...' : 'No cabals are currently available to join.',
    cabal_image: '/placeholder.svg'
  };

  // Query to get member count for current cabal
  const { data: memberCount = 0 } = useQuery({
    queryKey: ['cabalMembers', currentCabal.id],
    queryFn: async () => {
      if (!currentCabal.id) return 0;
      
      const { data: team } = await supabase
        .from('teams')
        .select('team_members')
        .eq('id', currentCabal.id)
        .single();
      
      if (team?.team_members) {
        return Array.isArray(team.team_members) 
          ? team.team_members.length 
          : Object.keys(team.team_members).length;
      }
      
      return 0;
    },
    enabled: !!currentCabal.id
  });

  const getStorageUrl = (imagePath) => {
    if (!imagePath) return '/placeholder.svg';
    if (imagePath.startsWith('http')) return imagePath;
    
    try {
      const { data: { publicUrl } } = supabase
        .storage
        .from('cabal-logos')
        .getPublicUrl(imagePath);
      
      return publicUrl;
    } catch (error) {
      console.error('Error getting storage URL:', error);
      return '/placeholder.svg';
    }
  };

  const handleImageError = (cabalId) => {
    setImageError(prev => ({ ...prev, [cabalId]: true }));
    toast({
      title: "Image Load Error",
      description: "Could not load cabal image",
      variant: "destructive",
    });
  };

  return (
    <div className="w-full bg-[#191E1F] text-[#D4D0D4] font-mono text-[10px] leading-[1.2] relative">
      <div className="p-5 border-3 border-[#454545] relative">
        <div className="absolute -top-2 left-2 bg-[#2C2D2E] px-2 z-10">
          <span className="text-[#9BEDFF]">/join_cabal</span>
        </div>
        <div 
          className="bg-[#454545] p-4 mb-4"
          style={{ boxShadow: "rgba(81, 81, 81, 0.25) 4px 4px" }}
        >
          <p>
            {currentCabal.cabal_description || "No description available"}
          </p>
        </div>
        <div className="flex justify-center items-center mb-4">
          <div className="w-12 h-12 bg-white opacity-30 mr-2" />
          <img
            src={imageError[currentCabal.id] ? '/placeholder.svg' : getStorageUrl(currentCabal.cabal_image)}
            alt={currentCabal.cabal_name}
            width="144"
            height="144"
            className="mx-2 object-cover"
            onError={() => handleImageError(currentCabal.id)}
          />
          <div className="w-12 h-12 bg-white opacity-30 ml-2" />
        </div>
        
        <div 
          className="bg-[#2C2D2E] border-3 border-[#454545] p-2 mb-6"
          style={{ 
            boxShadow: "rgba(116, 102, 90, 0.157) 4px 4px inset, rgba(81, 81, 81, 0.25) 4px 4px" 
          }}
        >
          <div className="flex items-center mb-1">
            <div 
              className="w-2 h-2 bg-[#FFCD70] mr-1"
              style={{ boxShadow: "rgba(81, 81, 81, 0.19) 2px 2px" }}
            />
            <span className="mr-1">name:</span>
            <span>{currentCabal.cabal_name}</span>
          </div>
          <div className="flex items-center mb-1">
            <div 
              className="w-2 h-2 bg-[#FFCD70] mr-1"
              style={{ boxShadow: "rgba(81, 81, 81, 0.19) 2px 2px" }}
            />
            <span className="mr-1">status:</span>
            <span>active</span>
          </div>
          <div className="flex items-center mb-1">
            <div 
              className="w-2 h-2 bg-[#FFCD70] mr-1"
              style={{ boxShadow: "rgba(81, 81, 81, 0.19) 2px 2px" }}
            />
            <span className="mr-1">members:</span>
            <span>{memberCount} / 100</span>
          </div>

          <div className="flex justify-between items-center mt-2 bg-black bg-opacity-20">
            <button 
              onClick={prevPage}
              className="text-[#9BEDFF] hover:text-[#00A0B8] transition-colors"
              disabled={cabals.length <= 1}
            >
              <ChevronLeft size={12} />
            </button>
            <span className="text-[#A3A3A3]">
              {currentPage} / {totalPages}
            </span>
            <button 
              onClick={nextPage}
              className="text-[#9BEDFF] hover:text-[#00A0B8] transition-colors"
              disabled={cabals.length <= 1}
            >
              <ChevronRight size={12} />
            </button>
          </div>
        </div>
        <button
          onClick={() => onJoinRequest(currentCabal)}
          className="w-full bg-[#191E1F] border-3 border-[#454545] py-1 px-4 hover:text-[#00A0B8] transition-colors"
          style={{
            boxShadow: "rgba(81, 81, 81, 0.25) 4px 4px",
          }}
          disabled={!currentCabal.id}
        >
          <span>{currentCabal.id ? 'Apply to join' : 'No cabal available'}</span>
        </button>
      </div>
    </div>
  );
};

export default JoinCabalSlider;