import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { formatDistanceToNow } from 'date-fns';
import { useTrades } from '@/hooks/useTrades';

export function TradesTable({ address }: { address?: string }) {
  const { data: trades, isLoading, error } = useTrades(address);

  if (isLoading) {
    return (
      <div className="text-center p-4 text-[#CBE9ED]">
        Loading trades...
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 p-4">
        Error loading trades: {error.message}
      </div>
    );
  }

  if (!trades?.length) {
    return (
      <div className="text-center p-4 text-[#CBE9ED]">
        No trades found
      </div>
    );
  }

  return (
    <div className="overflow-x-auto">
      <Table>
        <TableHeader>
          <TableRow className="border-b border-[#00A0B8]">
            <TableHead className="text-[#00A0B8]">Time</TableHead>
            <TableHead className="text-[#00A0B8]">Symbol</TableHead>
            <TableHead className="text-[#00A0B8]">Side</TableHead>
            <TableHead className="text-[#00A0B8]">Price</TableHead>
            <TableHead className="text-[#00A0B8]">Platform</TableHead>
            <TableHead className="text-[#00A0B8]">Transaction</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {trades.map((trade) => (
            <TableRow 
              key={trade.transactionHash}
              className="border-b border-[#2A2A2A] hover:bg-[#1A1A1A]"
            >
              <TableCell className="text-[#CBE9ED]">
                {formatDistanceToNow(new Date(trade.createdAt), { addSuffix: true })}
              </TableCell>
              <TableCell className="text-[#CBE9ED]">{trade.symbol}</TableCell>
              <TableCell className={trade.side === 'buy' ? 'text-green-500' : 'text-red-500'}>
                {trade.side.toUpperCase()}
              </TableCell>
              <TableCell className="text-[#CBE9ED]">${trade.price.toFixed(6)}</TableCell>
              <TableCell className="text-[#CBE9ED]">{trade.platform}</TableCell>
              <TableCell>
                <a
                  href={`https://tonscan.org/tx/${trade.transactionHash}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#00A0B8] hover:text-[#00D1F4] transition-colors"
                >
                  {trade.transactionHash.slice(0, 8)}...
                </a>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}