import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AsciiNoiseBackground from '../components/AsciiNoiseBackground';

const LandingPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'F1') {
        event.preventDefault();
        navigate('/register-leader');
      } else if (event.key === 'F2') {
        event.preventDefault();
        navigate('/register-member');
      } else if (event.key === 'F3') {
        event.preventDefault();
        navigate('/dashboard');
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [navigate]);

  return (
    <div className="min-h-screen bg-[#121212] flex items-center justify-center p-4 relative">
      <AsciiNoiseBackground />
      <div className="w-[460px] bg-[#121212] text-[#CBE9ED] font-mono text-xs p-4 relative z-10" style={{
        boxShadow: 'rgba(116, 102, 90, 0.157) 4px 4px inset, rgba(0, 0, 0, 0.25) 8px 8px',
        border: '3px solid #00A0B8',
      }}>
        <div className="absolute top-0 left-2 -mt-2 bg-[#024652] px-2 z-10">
          <span className="text-[#F02D3B]">/welcome</span>
        </div>
        <h1 className="text-xl font-bold text-center mb-4">Welcome to Cabal Arena</h1>
        <img src="/dancing.gif" alt="Cabal Arena" className="w-full mb-4" />
        <p className="mb-6 text-center">Choose your path in the memecoin trading competition!</p>
        <div className="space-y-4">
          <Link to="/register-leader" className="block">
            <button className="w-full bg-[#1A1A1A] border border-[#00A0B8] p-2 text-[#00A0B8] hover:bg-[#002227] focus:outline-none focus:ring-1 focus:ring-[#00A0B8] transition-colors" style={{
              boxShadow: '2px 2px 0 rgba(0,0,0,0.25)',
            }}>
              [F1] Register as Cabal Leader
            </button>
          </Link>
          <Link to="/register-member" className="block">
            <button className="w-full bg-[#1A1A1A] border border-[#00A0B8] p-2 text-[#00A0B8] hover:bg-[#002227] focus:outline-none focus:ring-1 focus:ring-[#00A0B8] transition-colors" style={{
              boxShadow: '2px 2px 0 rgba(0,0,0,0.25)',
            }}>
              [F2] Join an Existing Cabal
            </button>
          </Link>
          <Link to="/dashboard" className="block">
            <button className="w-full bg-[#1A1A1A] border border-[#00A0B8] p-2 text-[#00A0B8] hover:bg-[#002227] focus:outline-none focus:ring-1 focus:ring-[#00A0B8] transition-colors" style={{
              boxShadow: '2px 2px 0 rgba(0,0,0,0.25)',
            }}>
              [F3] Go to Dashboard
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;