import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/components/ui/use-toast";
import { Loader2 } from "lucide-react";

export const JoinRequestDialog = ({ open, onOpenChange, onSubmit }) => {
  const [requestMessage, setRequestMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();

  const handleSubmit = async () => {
    if (!requestMessage.trim()) {
      toast({
        title: "Error",
        description: "Please enter a message for your join request",
        variant: "destructive",
      });
      return;
    }

    setIsSubmitting(true);
    try {
      await onSubmit(requestMessage);
      toast({
        title: "Success",
        description: "Your join request has been submitted successfully!",
      });
      setRequestMessage('');
      onOpenChange(false);
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "Failed to submit join request",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="bg-[#1A1A2E] border-2 border-[#00A0B8] text-[#CBE9ED] rounded-none">
        <DialogHeader>
          <DialogTitle>Request to Join Cabal</DialogTitle>
          <DialogDescription>
            Enter a message to accompany your join request (max 128 characters).
          </DialogDescription>
        </DialogHeader>
        <Textarea
          value={requestMessage}
          onChange={(e) => setRequestMessage(e.target.value.slice(0, 128))}
          placeholder="Enter your message here..."
          className="bg-[#121212] border border-[#00A0B8] text-[#CBE9ED] rounded-none"
          disabled={isSubmitting}
        />
        <DialogFooter>
          <Button
            onClick={handleSubmit}
            className="bg-[#1A1A1A] border border-[#00A0B8] text-[#00A0B8] hover:bg-[#002227] rounded-none"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Submitting...
              </>
            ) : (
              'Submit Request'
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};