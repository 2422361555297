import React from "react";
import { cn } from "@/lib/utils";

const RetroTable = React.forwardRef(({ className, ...props }, ref) => (
  <div className="relative w-full overflow-auto">
    <table
      ref={ref}
      className={cn(
        "w-full caption-bottom text-xs font-mono",
        className
      )}
      {...props}
    />
  </div>
));
RetroTable.displayName = "RetroTable";

const RetroTableHeader = React.forwardRef(({ className, ...props }, ref) => (
  <thead ref={ref} className={cn("[&_tr]:border-b border-[#3465A4]/20", className)} {...props} />
));
RetroTableHeader.displayName = "RetroTableHeader";

const RetroTableBody = React.forwardRef(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={cn("[&_tr:last-child]:border-0", className)}
    {...props}
  />
));
RetroTableBody.displayName = "RetroTableBody";

const RetroTableRow = React.forwardRef(({ className, ...props }, ref) => (
  <tr
    ref={ref}
    className={cn(
      "border-b border-[#3465A4]/20 transition-colors hover:bg-[#FCE94F]/5",
      className
    )}
    {...props}
  />
));
RetroTableRow.displayName = "RetroTableRow";

const RetroTableHead = React.forwardRef(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      "h-8 px-2 text-left align-middle font-mono text-[#FCE94F] text-xs [&:has([role=checkbox])]:pr-0",
      className
    )}
    {...props}
  />
));
RetroTableHead.displayName = "RetroTableHead";

const RetroTableCell = React.forwardRef(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={cn("px-2 py-1 align-middle font-mono text-xs text-[#CBE9ED] [&:has([role=checkbox])]:pr-0", className)}
    {...props}
  />
));
RetroTableCell.displayName = "RetroTableCell";

export {
  RetroTable,
  RetroTableHeader,
  RetroTableBody,
  RetroTableRow,
  RetroTableHead,
  RetroTableCell,
};