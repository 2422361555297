import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";

const TeamMemberConfirmation = ({ isOpen, onClose, members, onConfirm }) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="bg-[#1A1A1A] border-2 border-[#00A0B8] text-[#CBE9ED] max-w-[800px] w-full">
        <DialogHeader>
          <DialogTitle>Confirm Team Members</DialogTitle>
        </DialogHeader>
        <ScrollArea className="h-[400px] w-full pr-4">
          <div className="space-y-2">
            {members.map((member, index) => (
              <div
                key={index}
                className="p-2 bg-[#121212] border border-[#00A0B8] rounded flex items-center justify-between"
              >
                <span className="text-sm font-mono break-all">{member}</span>
                <span className="text-sm text-[#00A0B8] ml-2">#{index + 1}</span>
              </div>
            ))}
          </div>
        </ScrollArea>
        <DialogFooter className="flex justify-between mt-4">
          <Button
            onClick={onClose}
            variant="outline"
            className="bg-[#1A1A1A] border border-[#00A0B8] text-[#00A0B8] hover:bg-[#002227]"
          >
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            className="bg-[#1A1A1A] border border-[#00A0B8] text-[#00A0B8] hover:bg-[#002227]"
          >
            Confirm ({members.length} members)
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default TeamMemberConfirmation;