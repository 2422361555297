import React from 'react';
import {
  RetroTable,
  RetroTableHeader,
  RetroTableBody,
  RetroTableRow,
  RetroTableHead,
  RetroTableCell,
} from "@/components/ui/retro-table";
import { ChevronRight } from "lucide-react";

export const CabalMemberTable = ({ members }) => (
  <RetroTable>
    <RetroTableHeader>
      <RetroTableRow>
        <RetroTableHead className="w-[50px]">#</RetroTableHead>
        <RetroTableHead>Member Name</RetroTableHead>
        <RetroTableHead>Individual Score</RetroTableHead>
        <RetroTableHead>PnL</RetroTableHead>
        <RetroTableHead>Volume</RetroTableHead>
        <RetroTableHead>Competitors Sniped</RetroTableHead>
      </RetroTableRow>
    </RetroTableHeader>
    <RetroTableBody>
      {members.sort((a, b) => b.individualScore - a.individualScore).map((member, index) => (
        <RetroTableRow key={member.id}>
          <RetroTableCell className="flex items-center gap-1">
            <ChevronRight className="h-3 w-3 text-[#FCE94F]" />
            {index + 1}
          </RetroTableCell>
          <RetroTableCell>{member.name}</RetroTableCell>
          <RetroTableCell>{member.individualScore.toLocaleString()}</RetroTableCell>
          <RetroTableCell>${member.pnl.toLocaleString()}</RetroTableCell>
          <RetroTableCell>${member.volume.toLocaleString()}</RetroTableCell>
          <RetroTableCell>{member.competitorsSniped}</RetroTableCell>
        </RetroTableRow>
      ))}
    </RetroTableBody>
  </RetroTable>
);