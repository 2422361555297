import React from 'react';
import { Terminal, Users, Key } from 'lucide-react';
import { Card } from "@/components/ui/card";
import InviteCodeSection from '../components/leader-panel/InviteCodeSection';
import JoinRequestsSection from '../components/leader-panel/JoinRequestsSection';
import TeamMembersSection from '../components/leader-panel/TeamMembersSection';
import { useWalletAuth } from "../WalletAuthContext";
import { useLeaderDashboard } from "../hooks/useLeaderDashboard";
import AsciiNoiseBackground from '../components/AsciiNoiseBackground';

const CabalLeaderPanel = () => {
  const { userAddress } = useWalletAuth();
  const { data: dashboardData, isLoading } = useLeaderDashboard(userAddress);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-[#121212] text-[#CBE9ED] font-mono p-4">
        <div className="flex items-center justify-center h-full">
          Loading dashboard data...
        </div>
      </div>
    );
  }

  const cabalData = dashboardData?.cabal || {};

  return (
    <div className="min-h-screen bg-[#121212] text-[#CBE9ED] font-mono p-4 relative">
      <AsciiNoiseBackground />
      <div className="max-w-7xl mx-auto relative z-10">
        <header className="mb-8 border-b border-[#00A0B8] pb-4">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-bold text-[#00A0B8] flex items-center gap-2">
              <Terminal className="w-6 h-6" />
              CABAL_CONTROL_PANEL.exe
            </h1>
            <div className="text-sm">
              <span className="text-[#F02D3B]">LEADER_ADDRESS: </span>
              {userAddress?.slice(0, 4)}...{userAddress?.slice(-4)}
            </div>
          </div>
        </header>

        <div className="grid grid-cols-1 gap-6">
          <Card className="bg-[#1A1A1A] border-[#00A0B8] p-4 shadow-[4px_4px_0_rgba(0,0,0,0.25)]">
            <div className="flex items-center gap-2 mb-4">
              <Key className="w-4 h-4 text-[#00A0B8]" />
              <h2 className="text-[#00A0B8] text-lg">INVITE_SYSTEM.dat</h2>
            </div>
            <InviteCodeSection inviteCode={cabalData.invite_code} />
          </Card>

          <Card className="bg-[#1A1A1A] border-[#00A0B8] p-4 shadow-[4px_4px_0_rgba(0,0,0,0.25)]">
            <div className="flex items-center gap-2 mb-4">
              <Users className="w-4 h-4 text-[#00A0B8]" />
              <h2 className="text-[#00A0B8] text-lg">JOIN_REQUESTS.dat</h2>
            </div>
            <JoinRequestsSection requests={dashboardData?.pending_requests || []} />
          </Card>

          <Card className="bg-[#1A1A1A] border-[#00A0B8] p-4 shadow-[4px_4px_0_rgba(0,0,0,0.25)]">
            <div className="flex items-center gap-2 mb-4">
              <Users className="w-4 h-4 text-[#00A0B8]" />
              <h2 className="text-[#00A0B8] text-lg">TEAM_MEMBERS.dat</h2>
            </div>
            <TeamMembersSection members={dashboardData?.members || []} />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default CabalLeaderPanel;