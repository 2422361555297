import React from 'react';

const TeamMembersSection = ({ members = [] }) => {
  // Get team members from the correct location if available
  const teamMembers = Array.isArray(members) && members.length === 0 && members.cabal?.team_members 
    ? members.cabal.team_members 
    : members;

  return (
    <div className="space-y-4">
      <div className="bg-[#1A1A1A] border border-[#00A0B8] rounded-none p-4">
        <div className="font-mono text-[#CBE9ED] text-xs">
          <div className="grid grid-cols-12 gap-4 mb-2 border-b border-[#00A0B8] pb-2">
            <div className="col-span-1">#</div>
            <div className="col-span-5">Wallet Address</div>
            <div className="col-span-3">Raw Address</div>
            <div className="col-span-3">Nickname</div>
          </div>
          {Array.isArray(teamMembers) && teamMembers.length > 0 ? (
            teamMembers.map((member, index) => {
              const address = member.address || member.S;
              const displayAddress = address ? 
                `${address.slice(0, 6)}...${address.slice(-4)}` : 
                'Unknown';
              const rawAddress = member.raw_address ? 
                `${member.raw_address.slice(0, 6)}...${member.raw_address.slice(-4)}` :
                'N/A';

              return (
                <div key={index} className="grid grid-cols-12 gap-4 py-2 border-b border-[#00A0B8]/20">
                  <div className="col-span-1">{index + 1}</div>
                  <div className="col-span-5 font-mono">
                    {displayAddress}
                  </div>
                  <div className="col-span-3 font-mono text-[#9BEDFF]">
                    {rawAddress}
                  </div>
                  <div className="col-span-3">
                    {member.nickname || 'N/A'}
                  </div>
                </div>
              );
            })
          ) : (
            <div className="text-center py-4">No team members found</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeamMembersSection;