import React, { useState } from 'react';
import { Label } from "@/components/ui/label";
import TeamMemberChipInput from './TeamMemberChipInput';
import TeamMemberConfirmation from './TeamMemberConfirmation';
import { Button } from "@/components/ui/button";
import { toast } from "@/components/ui/use-toast";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "../integrations/supabase/supabase";

const TeamMembersForm = ({ formData, setFormData }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [members, setMembers] = useState(
    formData.teamMembers ? formData.teamMembers.split('\n').filter(m => m.trim()) : []
  );
  const [validatingAddress, setValidatingAddress] = useState('');

  // Query to check if member address is already registered
  const { refetch: checkMemberAddress } = useQuery({
    queryKey: ['checkTeamMember', validatingAddress],
    queryFn: async () => {
      if (!validatingAddress) return null;
      
      const { data, error } = await supabase
        .from('trader_profiles')
        .select('cabal_name')
        .eq('address', validatingAddress)
        .single();
        
      if (error && error.code !== 'PGRST116') {
        throw error;
      }
      return data;
    },
    enabled: false
  });

  const handleAddMember = async (address) => {
    if (!address.trim()) return;

    setValidatingAddress(address);
    const { data } = await checkMemberAddress();
    
    if (data) {
      toast({
        title: "Address already registered",
        description: `This address is already registered with cabal: ${data.cabal_name}`,
        variant: "destructive",
      });
      return false;
    }

    const newMembers = [...members, address];
    setMembers(newMembers);
    return true;
  };

  const handleConfirm = () => {
    if (members.length < 10) {
      toast({
        title: "Not enough members",
        description: "You need at least 10 team members",
        variant: "destructive",
      });
      return;
    }
    
    setFormData({ 
      ...formData, 
      teamMembers: members.join('\n') 
    });
    setShowConfirmation(false);
    
    toast({
      title: "Team members updated",
      description: `Successfully added ${members.length} team members`,
    });
  };

  return (
    <div className="mb-4">
      <Label htmlFor="teamMembers" className="block mb-1 relative pl-4">
        <span className="absolute left-0 top-0 text-[#F02D3B]">■</span>
        Team Member Addresses (min 10, max 100)
      </Label>
      
      <TeamMemberChipInput 
        members={members}
        onMembersChange={setMembers}
        onAddMember={handleAddMember}
      />
      
      <div className="mt-4">
        <Button
          onClick={() => setShowConfirmation(true)}
          className="w-full bg-[#1A1A1A] border border-[#00A0B8] text-[#00A0B8] hover:bg-[#002227]"
          disabled={members.length < 10}
        >
          Review Team Members ({members.length}/100)
        </Button>
      </div>

      <TeamMemberConfirmation
        isOpen={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        members={members}
        onConfirm={handleConfirm}
      />
    </div>
  );
};

export default TeamMembersForm;