import { useState, useEffect } from 'react';
import { toast } from "sonner";

export const useWalletBalance = (userAddress) => {
  const [walletBalance, setWalletBalance] = useState(0);

  useEffect(() => {
    const fetchWalletBalance = async () => {
      if (!userAddress) return;

      try {
        const response = await fetch(`https://ton-mainnet.core.chainstack.com/28f8b7ffc5f10a88dd79dadef1188cb3/api/v3/account?address=${userAddress}`);
        
        if (!response.ok) {
          throw new Error('Failed to fetch balance');
        }

        const data = await response.json();
        setWalletBalance(Number(data.balance) / 1e9);
      } catch (error) {
        console.error('Error fetching wallet balance:', error);
        toast.error('Failed to load wallet balance');
        setWalletBalance(0);
      }
    };

    fetchWalletBalance();
    // Refresh balance every 30 seconds
    const intervalId = setInterval(fetchWalletBalance, 30000);

    return () => clearInterval(intervalId);
  }, [userAddress]);

  return walletBalance;
};