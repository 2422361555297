import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from "@/components/ui/button";

const ReturnToLandingButton = () => {
  return (
    <div className="fixed bottom-4 left-4">
      <Link to="/">
        <Button variant="outline" size="sm">
          Return to Landing Page
        </Button>
      </Link>
    </div>
  );
};

export default ReturnToLandingButton;