import React from 'react';
import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-react";

const SubmitButton = ({ isFormValid, handleSubmit, isLoading, isWalletRegistered, isMember }) => {
  return (
    <Button
      onClick={handleSubmit}
      disabled={!isFormValid || isLoading || isWalletRegistered || isMember}
      className="w-full bg-[#1A1A1A] border border-[#00A0B8] p-2 text-[#00A0B8] hover:bg-[#002227] focus:outline-none focus:ring-1 focus:ring-[#00A0B8] transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
      style={{ boxShadow: '2px 2px 0 rgba(0,0,0,0.25)' }}
    >
      {isLoading ? (
        <>
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          Registering...
        </>
      ) : isWalletRegistered ? (
        'Wallet Already Registered as Cabal Leader'
      ) : isMember ? (
        'Wallet Already Registered as Team Member'
      ) : (
        '[F5] Register as Cabal Leader'
      )}
    </Button>
  );
};

export default SubmitButton;