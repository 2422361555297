import { supabase } from './supabase';

export const dbFunctions = {
  // User related functions
  async getUserProfile(address) {
    const { data, error } = await supabase
      .rpc('get_user_profile', { user_address: address });
    if (error) throw error;
    return data;
  },

  async updateUserProfile(address, updates) {
    const { data, error } = await supabase
      .rpc('update_user_profile', { 
        user_address: address,
        profile_data: updates 
      });
    if (error) throw error;
    return data;
  },

  // Game related functions
  async getGameHistory(address) {
    const { data, error } = await supabase
      .rpc('get_user_game_history', { user_address: address });
    if (error) throw error;
    return data;
  },

  async getLeaderboard() {
    const { data, error } = await supabase
      .rpc('get_leaderboard');
    if (error) throw error;
    return data;
  },

  // Tournament related functions
  async getTournaments() {
    const { data, error } = await supabase
      .rpc('get_active_tournaments');
    if (error) throw error;
    return data;
  },

  async joinTournament(tournamentId, address) {
    const { data, error } = await supabase
      .rpc('join_tournament', { 
        tournament_id: tournamentId,
        user_address: address 
      });
    if (error) throw error;
    return data;
  }
};