import { useState, useEffect } from 'react';
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";

export function PrizePool() {
  const prizeShares = [
    { rank: 1, share: 25 },
    { rank: 2, share: 18 },
    { rank: 3, share: 12 },
    { rank: 4, share: 9 },
    { rank: 5, share: 8 },
    { rank: 6, share: 7 },
    { rank: 7, share: 6 },
    { rank: 8, share: 5 },
    { rank: 9, share: 5 },
    { rank: 10, share: 5 },
  ];

  const { data: cabalScores } = useQuery({
    queryKey: ['cabalScores'],
    queryFn: async () => {
      const { data, error } = await supabase
        .rpc('get_cabals')
        .limit(10);
      
      if (error) throw error;
      return data;
    },
    refetchInterval: 30000
  });

  const progress = 33.33; // Hardcoded to 1/3 of the progress bar
  const [totalPrize, setTotalPrize] = useState(350000);
  const maxPrize = 600000;

  useEffect(() => {
    if (cabalScores?.length > 0) {
      setTotalPrize(350000 + (progress / 100) * 250000);
    }
  }, [cabalScores, progress]);

  return (
    <div className="w-full bg-[#191E1F] text-[#D4D0D4] font-mono text-[10px] leading-[1.2] relative mb-4" style={{
      fontFamily: 'Consolas, monaco, monospace',
      textAlign: 'left',
      textShadow: 'rgba(0, 0, 0, 0.44) 1px 1px',
    }}>
      <style jsx>{`
        @keyframes spin {
          0% { transform: rotateY(0deg); }
          100% { transform: rotateY(180deg); }
        }
        .coin {
          width: 12px;
          height: 12px;
          background-color: #FFD700;
          border-radius: 50%;
          display: inline-block;
          vertical-align: middle;
          margin-left: 4px;
          box-shadow: inset -2px -2px 0px 0px rgba(0,0,0,0.3);
          animation: spin 1s infinite linear;
        }
        .coin::after {
          content: "$";
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          font-size: 8px;
          color: #B8860B;
          text-shadow: none;
        }
      `}</style>
      <div className="p-5 border-3 border-[#454545] relative" style={{
        boxShadow: 'rgba(116, 102, 90, 0.157) 4px 4px inset, rgba(0, 0, 0, 0.25) 8px 8px',
      }}>
        <div className="absolute -top-2 left-2 bg-[#2C2D2E] px-2 z-10">
          <span className="text-[#9BEDFF]">/prize_pool</span>
        </div>

        <div className="bg-[#454545] p-4 mb-4" style={{ boxShadow: 'rgba(81, 81, 81, 0.25) 4px 4px' }}>
          <p className="text-center mb-2 text-[14px] font-bold">Prize Pool</p>
          <p className="text-center mb-2">
            The dynamic prize pool can reach up to <span className="text-[#9BEDFF]">${maxPrize.toLocaleString()}</span>
          </p>
          <div className="w-full bg-[#2C2D2E] h-4 relative overflow-hidden" style={{
            boxShadow: 'rgba(0, 0, 0, 0.25) 2px 2px inset',
          }}>
            <div
              className="h-full bg-[#9BEDFF] transition-all duration-500"
              style={{
                width: `${progress}%`,
                boxShadow: 'rgba(155, 237, 255, 0.5) 0px 0px 10px',
              }}
            ></div>
          </div>
          <div className="flex justify-between text-[8px] mt-1">
            <span>$350,000</span>
            <span className="text-[#9BEDFF]">${Math.round(totalPrize).toLocaleString()}</span>
            <span>${maxPrize.toLocaleString()}</span>
          </div>
        </div>

        <div className="bg-[#2C2D2E] border-3 border-[#454545] p-4" style={{
          boxShadow: 'rgba(116, 102, 90, 0.157) 4px 4px inset, rgba(81, 81, 81, 0.25) 4px 4px',
        }}>
          <div className="grid grid-cols-3 gap-4 mb-2">
            <div className="text-[#9BEDFF]">Team Rank</div>
            <div className="text-[#9BEDFF]">Cabal Name</div>
            <div className="text-[#9BEDFF]">Prize Share</div>
          </div>

          {prizeShares.map(({ rank, share }, index) => (
            <div key={rank} className="grid grid-cols-3 gap-4 mb-1">
              <div>{rank}</div>
              <div className="truncate">
                {cabalScores?.[index]?.cabal_name || '---'}
              </div>
              <div className="flex items-center justify-between">
                <span className="mr-2">{share}%</span>
                <span className="flex items-center">
                  ${Math.round(totalPrize * (share / 100)).toLocaleString()}
                  <span className="coin" aria-hidden="true"></span>
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}