import React, { useState } from "react";
import { useLeaderboardData } from "@/hooks/useLeaderboardData";
import { Terminal } from "lucide-react";
import { LeaderboardTable } from "./LeaderboardTable";
import { columns } from "./constants";
import { Skeleton } from "@/components/ui/skeleton";
import { PrizePool } from "./PrizePool";

export function LeaderboardContainer() {
  const [activeTab, setActiveTab] = useState("cabal-score");
  const { data, isLoading } = useLeaderboardData(activeTab);

  const TabButton = ({ tab, label }) => (
    <button
      onClick={() => setActiveTab(tab)}
      className={`
        px-6 py-3 font-mono text-[10px] transition-all
        ${activeTab === tab 
          ? 'bg-[#454545] text-[#9BEDFF] border-t-2 border-[#9BEDFF]' 
          : 'text-[#D4D0D4] hover:bg-[#2C2D2E]'
        }
      `}
    >
      {label}
    </button>
  );

  return (
    <div className="bg-[#191E1F] text-[#D4D0D4] font-mono text-[10px] leading-[1.2]" style={{
      fontFamily: 'Consolas, monaco, monospace',
      textShadow: 'rgba(0, 0, 0, 0.44) 1px 1px',
    }}>
      <PrizePool />
      
      <div className="p-5 border-3 border-[#454545] relative" style={{
        boxShadow: 'rgba(116, 102, 90, 0.157) 4px 4px inset, rgba(0, 0, 0, 0.25) 8px 8px',
      }}>
        <div className="absolute -top-2 left-2 bg-[#2C2D2E] px-2 z-10">
          <Terminal className="w-4 h-4 text-[#9BEDFF] inline-block mr-2" />
          <span className="text-[#9BEDFF]">/leaderboard</span>
        </div>

        <div className="flex border-b border-[#454545] mb-4">
          <TabButton tab="cabal-score" label="Cabal Performance" />
          <TabButton tab="meme-pool" label="Meme Pool" />
          <TabButton tab="best-snipers" label="Best Snipers" />
        </div>

        {isLoading ? (
          <div className="space-y-2">
            {[...Array(7)].map((_, i) => (
              <Skeleton key={i} className="h-12 bg-[#2C2D2E]" />
            ))}
          </div>
        ) : (
          <LeaderboardTable 
            columns={columns[activeTab]} 
            data={data?.slice(0, 7) || []} 
            activeTab={activeTab}
          />
        )}
      </div>
    </div>
  );
}