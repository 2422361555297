import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/lib/supabase';
export interface TraderCabalData {
  is_member: boolean;
  message?: string;
  cabal?: {
    id: string;
    name: string;
    description: string;
    image: string;
    telegram_link: string;
    members_count: number;
    slots_available: number;
    content: any;
  };
  profile?: {
    address: string;
    nickname: string;
    joined_at: string;
    invite_code: string;
  };
  stats?: {
    individual_score: number;
    own_pnl: number;
    others_pnl: number;
    own_volume: number;
    others_volume: number;
    token_multiplier: number;
    wallet_multiplier: number;
    narrative_multiplier: number;
    competitors_sniped: number;
  };
  cabal_trades?: Array<{
    trader_address: string;
    symbol: string;
    amount: string;
    price: string;
    side: string;
    platform: string;
    created_at: string;
  }>;
  cabal_pnl?: {
    total_own_pnl: number;
    total_others_pnl: number;
    total_volume: number;
    traders_count: number;
  };
  top_traders?: Array<{
    address: string;
    nickname: string;
    individual_score: number;
    total_volume: number;
  }>;
}
export const useTraderCabal = (address?: string) => {
  return useQuery({
    queryKey: ['trader-cabal', address],
    queryFn: async () => {
      const { data, error } = await supabase
        .rpc('get_trader_cabal', { p_address: address });
      
      if (error) throw error;
      return data as TraderCabalData;
    },
    enabled: !!address
  });
};
