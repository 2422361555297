import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { formatCurrency } from '@/lib/utils';
import { motion } from 'framer-motion';

export function LeaderboardTable({ columns, data, activeTab }) {
  const formatValue = (value, column, rowIndex) => {
    if (column.toLowerCase() === 'rank') {
      return rowIndex + 1;
    }
    if (typeof value === 'string' && value.startsWith('$')) {
      return value;
    }
    if (column.toLowerCase().includes('marketcap') || column.toLowerCase().includes('volume') || column.toLowerCase().includes('pnl')) {
      return formatCurrency(value);
    }
    if (typeof value === 'number') {
      return value.toLocaleString();
    }
    if (column.toLowerCase() === 'nickname') {
      return value || 'Anonymous';
    }
    return value;
  };

  return (
    <div className="bg-[#2C2D2E] border-3 border-[#454545] rounded-none overflow-hidden w-full" style={{
      boxShadow: 'rgba(116, 102, 90, 0.157) 4px 4px inset, rgba(81, 81, 81, 0.25) 4px 4px',
    }}>
      <Table>
        <TableHeader>
          <TableRow className="border-b border-[#454545] bg-[#191E1F]">
            {columns.map((header, index) => (
              <TableHead 
                key={index} 
                className="text-[#9BEDFF] font-bold py-4 px-2"
              >
                {header}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.map((row, rowIndex) => (
            <motion.tr
              key={row.id || rowIndex}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: rowIndex * 0.05 }}
              className={`
                border-b border-[#454545] 
                hover:bg-[#191E1F] 
                cursor-pointer
                ${rowIndex === 0 ? 'bg-[#191E1F]/50' : ''}
              `}
            >
              {columns.map((column, colIndex) => {
                const key = column.toLowerCase().replace(/\s/g, '');
                const value = column.toLowerCase() === 'rank' ? rowIndex + 1 : (row[key] || row[column.toLowerCase()]);
                
                return (
                  <TableCell 
                    key={colIndex}
                    className={`py-4 px-2 ${
                      rowIndex === 0 ? 'text-[#9BEDFF]' : 'text-[#D4D0D4]'
                    }`}
                  >
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.3 }}
                    >
                      {formatValue(value, column, rowIndex)}
                    </motion.div>
                  </TableCell>
                );
              })}
            </motion.tr>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}