import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "../supabase";

const fromSupabase = async (query) => {
  const { data, error } = await query;
  if (error) throw new Error(error.message);
  return data;
};

export const useCabalRegistration = (id) =>
  useQuery({
    queryKey: ["teams", id],
    queryFn: () =>
      fromSupabase(supabase.from("teams").select("*").eq("id", id).single()),
    enabled: !!id,
  });

export const useCabalRegistrations = () =>
  useQuery({
    queryKey: ["teams"],
    queryFn: () => fromSupabase(supabase.from("teams").select("*")),
  });

export const useAddCabalRegistration = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (newRegistration) =>
      fromSupabase(
        supabase
          .from("teams")
          .insert([
            {
              cabal_name: newRegistration.cabal_name,
              cabal_description: newRegistration.cabal_description,
              cabal_image: newRegistration.cabal_logo,
              owner: newRegistration.leader_wallet_address,
              team_members: newRegistration.team_members,
              telegram_link: newRegistration.telegram_chat_link,
              deployment_addresses: newRegistration.deployment_addresses || [],
              invite_code: generateInviteCode(),
              content: {
                team_members: newRegistration.team_members,
                deployment_wallets: newRegistration.deployment_addresses,
              },
            },
          ])
          .select()
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["teams"]);
    },
  });
};

function generateInviteCode() {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let code = "";
  for (let i = 0; i < 12; i++) {
    code += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return code;
}

export const useUpdateCabalRegistration = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, ...updateData }) =>
      fromSupabase(supabase.from("teams").update(updateData).eq("id", id)),
    onSuccess: () => {
      queryClient.invalidateQueries(["teams"]);
    },
  });
};

export const useDeleteCabalRegistration = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id) =>
      fromSupabase(supabase.from("teams").delete().eq("id", id)),
    onSuccess: () => {
      queryClient.invalidateQueries(["teams"]);
    },
  });
};
