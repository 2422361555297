import React from 'react';
import { cn } from "@/lib/utils";

const RetroNotification = ({ 
  children, 
  variant = 'info',
  title,
  className 
}) => {
  const variantStyles = {
    info: 'border-[#00A0B8] text-[#00A0B8]',
    warning: 'border-[#FFA500] text-[#FFA500]',
    error: 'border-[#F02D3B] text-[#F02D3B]',
    success: 'border-[#36D399] text-[#36D399]'
  };

  return (
    <div className={cn(
      "border-2 p-4 bg-[#1A1A1A]",
      variantStyles[variant],
      className
    )}>
      {title && <h3 className="font-bold mb-2">{title}</h3>}
      {children}
    </div>
  );
};

export default RetroNotification;