import React from 'react';
import { Button } from "@/components/ui/button";
import { toast } from 'sonner';
import { useManageInviteCode } from '../../hooks/useLeaderDashboard';
import { useWalletAuth } from '../../WalletAuthContext';

const InviteCodeSection = ({ inviteCode }) => {
  const { userAddress } = useWalletAuth();
  const { mutate: manageInvite, isLoading } = useManageInviteCode();

  const handleRegenerateCode = () => {
    manageInvite({ 
      address: userAddress, 
      action: 'regenerate' 
    });
  };

  const handleDisableCode = () => {
    manageInvite({ 
      address: userAddress, 
      action: 'disable' 
    });
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(inviteCode);
      toast.success('Invite code copied to clipboard');
    } catch (err) {
      toast.error('Failed to copy invite code');
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-4">
        <code className="bg-[#121212] p-2 rounded font-mono text-[#00A0B8]">
          {inviteCode || 'No invite code available'}
        </code>
        {inviteCode && (
          <div className="flex gap-2">
            <Button
              onClick={copyToClipboard}
              className="bg-[#1A1A1A] border border-[#00A0B8] text-[#00A0B8] hover:bg-[#002227]"
            >
              Copy
            </Button>
            <Button
              onClick={handleRegenerateCode}
              disabled={isLoading}
              className="bg-[#1A1A1A] border border-[#00A0B8] text-[#00A0B8] hover:bg-[#002227]"
            >
              Regenerate
            </Button>
            <Button
              onClick={handleDisableCode}
              disabled={isLoading}
              className="bg-[#1A1A1A] border border-red-500 text-red-500 hover:bg-red-900/20"
            >
              Disable
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default InviteCodeSection;