import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useNavigate } from 'react-router-dom';
import { useToast } from "@/components/ui/use-toast";

const SuccessModal = ({ isOpen, onClose, inviteCode, cabalName }) => {
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleCopyCode = async () => {
    try {
      await navigator.clipboard.writeText(inviteCode);
      toast({
        title: "Copied!",
        description: "Invite code copied to clipboard",
      });
    } catch (err) {
      toast({
        title: "Failed to copy",
        description: "Please copy the code manually",
        variant: "destructive",
      });
    }
  };

  const handleDone = () => {
    onClose();
    navigate('/internal-dashboard');
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="bg-[#121212] border-2 border-[#00A0B8] text-[#CBE9ED]">
        <DialogHeader>
          <DialogTitle>Registration Successful!</DialogTitle>
          <DialogDescription>
            Your Cabal "{cabalName}" has been registered successfully.
          </DialogDescription>
        </DialogHeader>
        <div className="my-4 space-y-4">
          <p>Your invitation code is:</p>
          <div 
            className="bg-[#1A1A1A] p-3 border border-[#00A0B8] font-mono text-lg text-center cursor-pointer"
            onClick={handleCopyCode}
          >
            {inviteCode}
          </div>
          <p className="text-sm text-[#CBE9ED]/70">Click the code to copy to clipboard</p>
          <p className="mt-2">Share this code with your team members to join your Cabal.</p>
        </div>
        <DialogFooter>
          <Button 
            onClick={handleDone}
            className="w-full bg-[#1A1A1A] border border-[#00A0B8] text-[#00A0B8] hover:bg-[#002227]"
          >
            Done
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SuccessModal;