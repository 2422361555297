import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Loader2 } from "lucide-react";

const LoadingRegistrationModal = ({ isOpen }) => {
  return (
    <Dialog open={isOpen}>
      <DialogContent className="bg-[#121212] border-2 border-[#00A0B8] text-[#CBE9ED]">
        <DialogHeader>
          <DialogTitle>Registering Your Cabal</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col items-center justify-center p-6 space-y-4">
          <Loader2 className="h-8 w-8 animate-spin text-[#00A0B8]" />
          <p className="text-center">Please wait while we process your registration...</p>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default LoadingRegistrationModal;