import React, { useState } from 'react';
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "../integrations/supabase/supabase";
import { toast } from "@/components/ui/use-toast";

const DeploymentAddressesForm = ({ formData, setFormData }) => {
  const [validatingAddress, setValidatingAddress] = useState('');

  // Query to check if address is already registered
  const { refetch: checkAddress } = useQuery({
    queryKey: ['checkDeploymentAddress', validatingAddress],
    queryFn: async () => {
      if (!validatingAddress) return null;
      
      const { data, error } = await supabase
        .from('deployments')  // Updated from cabal_deployments to deployments
        .select('address')
        .eq('address', validatingAddress)
        .single();
        
      if (error && error.code !== 'PGRST116') {
        throw error;
      }
      return data;
    },
    enabled: false
  });

  const handleDeploymentAddressChange = async (index, value) => {
    const newAddresses = [...formData.deploymentAddresses];
    newAddresses[index] = value;

    // Only validate if the address is not empty
    if (value.trim()) {
      setValidatingAddress(value);
      const { data } = await checkAddress();
      
      if (data) {
        toast({
          title: "Address already registered",
          description: "This deployment address is already registered to another cabal",
          variant: "destructive",
        });
        return;
      }
    }

    setFormData({ ...formData, deploymentAddresses: newAddresses });
  };

  return (
    <>
      {[0, 1, 2, 3].map((index) => (
        <div key={index} className="mb-4">
          <Label htmlFor={`deploymentAddress${index + 1}`} className="block mb-1 relative pl-4">
            <span className="absolute left-0 top-0 text-[#F02D3B]">■</span>
            Deployment Address {index + 1}
          </Label>
          <Input
            id={`deploymentAddress${index + 1}`}
            value={formData.deploymentAddresses[index] || ''}
            onChange={(e) => handleDeploymentAddressChange(index, e.target.value)}
            placeholder={`Enter Deployment Address ${index + 1}`}
            className="w-full bg-[#1A1A1A] border border-[#00A0B8] p-1 focus:outline-none focus:ring-1 focus:ring-[#00A0B8] text-[#CBE9ED] hover:border-[#00D1E8] focus:border-[#00D1E8] caret-[#00A0B8] animate-caret"
            style={{ boxShadow: 'inset 2px 2px 0 rgba(0,0,0,0.25)' }}
          />
        </div>
      ))}
    </>
  );
};

export default DeploymentAddressesForm;