import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '../lib/supabase';
import { toast } from 'sonner';

export const useLeaderDashboard = (address) => {
  return useQuery({
    queryKey: ['leaderDashboard', address],
    queryFn: async () => {
      if (!address) return null;
      
      const { data, error } = await supabase
        .rpc('get_leader_dashboard', { p_address: address });
        
      if (error) {
        const parsedError = JSON.parse(error.message);
        toast.error(parsedError.message || 'Failed to load dashboard data');
        throw error;
      }
      
      return data;
    },
    enabled: !!address
  });
};

export const useManageInviteCode = () => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({ address, action }) => {
      const { data, error } = await supabase
        .rpc('manage_invite_code', { 
          p_address: address,
          p_action: action 
        });
        
      if (error) {
        const parsedError = JSON.parse(error.message);
        toast.error(parsedError.message || 'Failed to manage invite code');
        throw error;
      }
      
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['leaderDashboard']);
    }
  });
};

export const useManageJoinRequest = () => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({ address, requestId, action }) => {
      const { data, error } = await supabase
        .rpc('manage_join_request', { 
          p_address: address,
          p_request_id: requestId,
          p_action: action 
        });
        
      if (error) {
        const parsedError = JSON.parse(error.message);
        toast.error(parsedError.message || 'Failed to manage join request');
        throw error;
      }
      
      return data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['leaderDashboard']);
      toast.success(`Request ${data.action}ed successfully`);
    }
  });
};