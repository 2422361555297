export const generateAndStoreAsciiNoiseParams = () => {
  const storedParams = localStorage.getItem("asciiNoiseParams");
  if (storedParams) {
    return JSON.parse(storedParams);
  }

  const newParams = {
    seed: Math.floor(Math.random() * 1000000000),
    noiseVar: Math.random() * 0.0044,
    bgChar: [".", "o", " ", "=", "#", "~", "©", " ", "*", "+", "-", "x"][
      Math.floor(Math.random() * 12)
    ],
    hsbMode: Math.random() < 0.2,
    charStart: 33 + Math.round(Math.random() * 60),
    timeFactor: Math.random() * (1.777 - 1.488) + 0.777,
  };

  localStorage.setItem("asciiNoiseParams", JSON.stringify(newParams));
  return newParams;
};