import React, { useState } from 'react';
import { useToast } from "@/components/ui/use-toast";
import { uploadCabalLogo } from '../utils/supabaseStorage';
import CabalInfoForm from './CabalInfoForm';
import DeploymentAddressesForm from './DeploymentAddressesForm';
import TeamMembersForm from './TeamMembersForm';
import SubmitButton from './SubmitButton';
import LoadingRegistrationModal from './LoadingRegistrationModal';
import SuccessModal from './SuccessModal';
import { useTonAddress } from '@tonconnect/ui-react';
import { dbFunctions } from '../lib/db-functions';

const CabalRegistrationForm = ({ isWalletConnected, walletBalance }) => {
  const [formData, setFormData] = useState({
    cabalName: '',
    cabalDescription: '',
    telegramLink: '',
    deploymentAddresses: ['', '', '', ''],
    teamMembers: '',
    cabalLogo: null,
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [teamId, setTeamId] = useState(null);
  const { toast } = useToast();
  const userAddress = useTonAddress();

  const handleSubmit = async () => {
    if (!isFormValid) {
      toast({
        title: "Registration Failed",
        description: "Please fill in all required fields and ensure your wallet is connected with sufficient balance.",
        variant: "destructive",
      });
      return;
    }

    setShowLoadingModal(true);
    setIsLoading(true);
    
    try {
      let logoUrl = '';
      if (formData.cabalLogo) {
        logoUrl = await uploadCabalLogo(formData.cabalLogo);
      }

      // Parse team members into required format
      const teamMembersArray = formData.teamMembers
        .split('\n')
        .filter(line => line.trim() !== '')
        .map(address => ({
          address: address.trim(),
          nickname: `Member_${Math.random().toString(36).substr(2, 9)}`
        }));

      const result = await dbFunctions.createCabalForMemepad({
        cabalName: formData.cabalName,
        cabalDescription: formData.cabalDescription,
        telegramLink: formData.telegramLink,
        deploymentAddresses: formData.deploymentAddresses.filter(addr => addr.trim() !== ''),
        teamMembers: teamMembersArray,
        cabalLogo: logoUrl,
        owner: userAddress
      });

      if (result?.team_id) {
        setTeamId(result.team_id);
        setShowLoadingModal(false);
        setShowSuccessModal(true);
      } else {
        throw new Error('Failed to create cabal');
      }

    } catch (error) {
      console.error('Error in handleSubmit:', error);
      setShowLoadingModal(false);
      toast({
        title: "Registration Failed",
        description: error.message,
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }} className="space-y-4">
        <CabalInfoForm formData={formData} setFormData={setFormData} />
        <DeploymentAddressesForm formData={formData} setFormData={setFormData} />
        <TeamMembersForm formData={formData} setFormData={setFormData} />
        <SubmitButton 
          isFormValid={isFormValid} 
          handleSubmit={handleSubmit} 
          isLoading={isLoading}
        />
      </form>

      <LoadingRegistrationModal isOpen={showLoadingModal} />
      
      <SuccessModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        teamId={teamId}
        cabalName={formData.cabalName}
      />
    </>
  );
};

export default CabalRegistrationForm;